import React, { useState } from "react";
import CampaignIcon from "@mui/icons-material/Campaign";
import { Box, Card, Divider, Modal, Typography } from "@mui/material";
import "./Campaigns.css";
import email_envelope_letter from "../../assets/icons/email-envelope-letter-svgrepo-com.svg";
import { IconContext } from "react-icons";
import { RxCross1 } from "react-icons/rx";
import { COLORS } from "../../constants/theme";
import CustomInput from "../../components/CustomInput/CustomInput";
import Icons from "../../constants/Icons";
import axios from "axios";
import AXIOS from "../../utils/AXIOS";
import { connect } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import MainScreen from "../../components/Navbar/MainScreen";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

import EmailCampaignCreated from "../../assets/images/Email-campaign-created.svg";
import Spinner from "../../components/Spinner/Spinner";
function Campaigns(props) {
  const navigate = useNavigate();
  const [campaignCreateModal, setCampaignCreateModal] = useState(false);

  const [createCampaignData, setCreateCampaignData] = useState({
    CAMPAIGN_NAME: "",
    CAMPAIGN_DESCRIPTION: "",
    CAMPAIGN_NAME_ERROR: false,
    CAMPAIGN_DESCRIPTION_ERROR: false,
    CAMPAIGN_COMPLETED: false,
    CAMPAIGN_SAVE_LOADING: false,
  });

  const createCampaign = () => {
    if (createCampaignData.CAMPAIGN_NAME == "") {
      setCreateCampaignData((prev) => ({
        ...prev,
        CAMPAIGN_NAME_ERROR: true,
      }));
    } else {
      setCreateCampaignData((prev) => ({
        ...prev,
        CAMPAIGN_SAVE_LOADING: true,
      }));
      axios
        .post(AXIOS.defaultPort + AXIOS.createCampaign, {
          campaignData: {
            CAMPAIGN_NAME: createCampaignData.CAMPAIGN_NAME,
            CAMPAIGN_DESCRIPTION: createCampaignData.CAMPAIGN_DESCRIPTION,
          },
          auth_id: props.auth_id,
        })
        .then((response) => {
          setCreateCampaignData({
            CAMPAIGN_NAME: "",
            CAMPAIGN_DESCRIPTION: "",
            CAMPAIGN_NAME_ERROR: false,
            CAMPAIGN_DESCRIPTION_ERROR: false,
            CAMPAIGN_ID: response.data?.campaignId,
            CAMPAIGN_COMPLETED: true,
            CAMPAIGN_SAVE_LOADING: false,
          });
          toast.success("Campaign Created");
        })
        .catch((err) => {
          setCreateCampaignData((prev) => ({
            ...prev,
            CAMPAIGN_SAVE_LOADING: false,
          }));
          toast.error("Something went wrong");
        });
    }
  };
  return (
    <MainScreen>
      <div
        style={{
          padding: 10,
        }}
      >
        <div>
          <Toaster
            containerStyle={{
              top: 20,
              left: 20,
              bottom: 20,
              right: 20,
            }}
            gutter={24}
            toastOptions={{
              style: {
                border: "1px solid #grey",
                padding: "16px",
              },
              success: {
                style: {
                  opacity: 0.1,
                },
              },
            }}
          />
        </div>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: 3,
            paddingBottom: 1,
            paddingLeft: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                mr: 1,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <IconContext.Provider value={{ size: 25, color: "#000" }}>
                <FaArrowLeftLong />
              </IconContext.Provider>
            </Box>
            {/* <h9 className="header-text">Contact Capture</h9> */}
            <Typography
              style={{
                fontSize: 22,
                fontWeight: 600,
                color: "#000",
                marginRight: 10,
                textAlign: "start",
              }}
            >
              Create Campaign
            </Typography>
          </Box>
        </Box>

        {props.components_list.includes("create-regular-campaign") && (
          <div className="card">
            <strong> Regular Email</strong>
            <div className="icon">
              <img
                src={email_envelope_letter}
                style={{
                  width: 100,
                  height: 100,
                }}
              />
            </div>
            <div className="card__body">
              <div>
                <p>Get UI elements that help you stand out.</p>
                <button
                  style={{
                    minWidth: 150,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    padding: 5,
                    marginTop: 10,
                    backgroundColor: "#1770e0",
                  }}
                  onClick={() => {
                    // DownloadImportFormat();
                    setCampaignCreateModal(true);
                  }}
                  className="signup-button"
                >
                  Add Campaign
                  <CampaignIcon />
                </button>
              </div>
            </div>
          </div>
        )}

        <Modal
          open={campaignCreateModal}
          onClose={() => {
            setCampaignCreateModal(false);
            setCreateCampaignData((prev) => ({
              ...prev,
              CAMPAIGN_COMPLETED: false,
            }));
          }}
          disableAutoFocus
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="edit-modal-container"
            sx={{
              p: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: 17,
                  color: COLORS.gray70,
                  fontWeight: "bold",
                }}
              >
                Create Campaign
              </Typography>
              <div
                style={{
                  cursor: "pointer",
                }}
              >
                <IconContext.Provider value={{ size: 15, marginTop: 5 }}>
                  <RxCross1
                    onClick={() => {
                      setCampaignCreateModal(false);
                      setCreateCampaignData((prev) => ({
                        ...prev,
                        CAMPAIGN_COMPLETED: false,
                      }));
                    }}
                  />
                </IconContext.Provider>
              </div>
            </Box>
            <Divider />
            {createCampaignData?.CAMPAIGN_COMPLETED == false && (
              <>
                <Box
                  sx={{
                    mt: 2,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 15,
                      color: "#000",
                      textAlign: "start",
                    }}
                  >
                    Campaign Name
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span>
                  </Typography>
                  <CustomInput
                    Placeholder={"Campaign Name"}
                    // icon={Icons.campaign}
                    Value={createCampaignData.CAMPAIGN_NAME}
                    onChange={(e) => {
                      setCreateCampaignData((prev) => ({
                        ...prev,
                        CAMPAIGN_NAME: e.target.value,
                        CAMPAIGN_NAME_ERROR: false,
                      }));
                    }}
                  />
                  {createCampaignData.CAMPAIGN_NAME_ERROR && (
                    <p className="helper-text">
                      Please enter Campaign Description
                    </p>
                  )}

                  <Typography
                    style={{
                      fontSize: 15,
                      color: "#000",
                      textAlign: "start",
                    }}
                  >
                    Description
                  </Typography>
                  <div className="group">
                    <textarea
                      rows={5}
                      className="text-box-style"
                      style={{
                        height: 70,
                        fontFamily: "sans-serif",
                      }}
                      placeholder={"Description"}
                      // icon={Icons.campaign}
                      value={createCampaignData.NAME}
                      onChange={(e) => {
                        setCreateCampaignData((prev) => ({
                          ...prev,
                          CAMPAIGN_DESCRIPTION: e.target.value,
                          CAMPAIGN_DESCRIPTION_ERROR: false,
                        }));
                      }}
                    />
                  </div>
                  {createCampaignData.CAMPAIGN_DESCRIPTION_ERROR && (
                    <p className="helper-text">
                      Please enter Campaign Description
                    </p>
                  )}
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    style={{
                      minWidth: 150,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",

                      padding: 5,
                      marginTop: 10,
                      backgroundColor: "#1770e0",
                    }}
                    disabled={createCampaignData.CAMPAIGN_SAVE_LOADING}
                    onClick={() => {
                      createCampaign();
                    }}
                    className="signup-button"
                  >
                    {createCampaignData.CAMPAIGN_SAVE_LOADING == true ? (
                      <Spinner />
                    ) : (
                      <>
                        Add Campaign
                        <CampaignIcon />
                      </>
                    )}
                  </button>
                </div>
              </>
            )}
            {createCampaignData?.CAMPAIGN_COMPLETED == true && (
              <>
                <img
                  src={EmailCampaignCreated}
                  style={{
                    width: "100%",
                    height: 300,
                  }}
                />
                <p
                  className="text-means-web recipient-description"
                  style={{
                    textAlign: "center",
                  }}
                >
                  The campaign has been created Successfully
                </p>
                <div
                  className="footer-buttons"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "20px",
                  }}
                >
                  <button
                    className="bordered-button"
                    onClick={() => {
                      setCampaignCreateModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="filled-button"
                    onClick={() => {
                      if (createCampaignData.CAMPAIGN_ID) {
                        navigate("/campaign-details", {
                          state: {
                            campaign_id: createCampaignData.CAMPAIGN_ID,
                          },
                        });
                      } else {
                        toast.error("Campaign ID not found");
                      }
                    }}
                  >
                    View Details
                  </button>
                </div>
              </>
            )}
          </Box>
        </Modal>
      </div>
    </MainScreen>
  );
}

const mapStateToProps = (state) => ({
  auth_email_id: state.contactData.auth_email_id,
  auth_name: state.contactData.auth_name,
  auth_id: state.contactData.auth_id,
  components_list: state.contactData.components_list,
});

export default connect(mapStateToProps, {})(Campaigns);
