import React, { useEffect, useRef, useState } from "react";
import MainScreen from "../../components/Navbar/MainScreen";
import "./EmailTemplates.css";
import AXIOS from "../../utils/AXIOS";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Box } from "@mui/material";
import newDocumentIcon from "../../assets/icons/new-document.png";
import loadingAnime from "../../assets/lottieFiles/loader.json";
import Lottie from "react-lottie-player";
import { connect } from "react-redux";

function EmailTemplates(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [transactionFlags, setTransactionFlags] = useState({
    TBODY_LOADING: true,
  });

  useEffect(() => {
    getEmailTemplates();
  }, [props.auth_id]);

  const [Tbody, setTbody] = useState([]);
  const getEmailTemplates = () => {
    fetch(AXIOS.defaultPort + AXIOS.getTemplate, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Sending JSON data
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setTransactionFlags({
          TBODY_LOADING: false,
        });

        setTbody(data);
      })
      .catch((err) => {
        setTransactionFlags({
          TBODY_LOADING: false,
        });
        toast.error("Something went wrong");
      });
  };

  return (
    <MainScreen>
      <Toaster />
      <Box
        sx={{
          pt: 2,
        }}
      />

      {transactionFlags.TBODY_LOADING == true && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <Lottie
            style={{
              width: 250,
              height: 250,
            }}
            play
            //   speed={"1"}
            animationData={loadingAnime}
          />
        </Box>
      )}

      {transactionFlags.TBODY_LOADING == false && (
        <div className="email-template-grid-container">
          {props.components_list.includes("email-templates-new") && (
            <div
              className="email-template-grid-item"
              style={{
                border: "1px solid #d9d9d9",
                position: "relative",
              }}
              onClick={() => {
                navigate("/email-template-edit", {
                  state: {
                    campaign_id: location?.state?.campaign_id,
                    // HTML:val.TEMPLATE_HTML,
                    DESIGN: {},
                    SAVE_DESIGN: location?.state?.SAVE_DESIGN,
                    _id: null,
                  },
                });
              }}
            >
              <img
                src={newDocumentIcon}
                style={{
                  width: 100,
                  height: 100,
                }}
              />
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "poppins",
                  marginTop: 10,
                }}
                className="bottom-text"
              >
                Pick blank, let your creativity take the stage!
              </p>
            </div>
          )}

          {props.components_list.includes("email-templates-existing") && (
            <>
              {Tbody.map((val, index) => (
                <div
                  className="email-template-grid-item"
                  key={index}
                  onClick={() => {
                    navigate("/email-template-edit", {
                      state: {
                        campaign_id: location?.state?.campaign_id,
                        // HTML:val.TEMPLATE_HTML,
                        DESIGN: val?.DESIGN,
                        SAVE_DESIGN: location?.state?.SAVE_DESIGN,
                        _id: val._id,
                      },
                    });
                  }}
                >
                  <img
                    src={AXIOS.defaultPort + val.TEMPLATE_IMAGE}
                    alt={`Template ${index}`}
                  />
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </MainScreen>
  );
}

const mapStateToProps = (state) => ({
  auth_id: state.contactData.auth_id,
  components_list: state.contactData.components_list,
});

export default connect(mapStateToProps, {})(EmailTemplates);
