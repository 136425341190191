import React, { Component, useEffect, useState } from "react";
import ReactDataTable from "../../components/ReactDataTable/ReactDataTable";
import MainScreen from "../../components/Navbar/MainScreen";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomLabel from "../../components/CustomLabel/CustomLabel";
import { Box, Card, Typography } from "@mui/material";
import "./RegisterComponents.css";
import CustomDropdown from "../../components/CustomDropDown/CustomDropDown";
import { IconContext } from "react-icons";
import { FaArrowLeftLong } from "react-icons/fa6";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import AXIOS from "../../utils/AXIOS";
import Spinner from "../../components/Spinner/Spinner";
import { connect } from "react-redux";

function RegisterComponents(props) {
  const navigate = useNavigate();
  const [tbody, setTbody] = useState();

  const [screenTbody, setScreenTbody] = useState([]);
  const [componentTbody, setComponentTbody] = useState([]);
  const [ScreenComponentTbody, setScreenComponentTbody] = useState([]);

  const [registerComponentsQuery, setRegisterComponentsQuery] = useState({
    COMPONENT_NAME: "",
    COMPONENT_ID: "",
    COMPONENT_NAME_ERROR: false,
    COMPONENT_ID_ERROR: false,
  });
  const [mapScreenComponentsQuery, setMapScreenComponentsQuery] = useState({
    SCREEN_ID: "",
    COMPONENT_ID: "",
    SCREEN_ID_ERROR: false,
    COMPONENT_ID_ERROR: false,
  });
  const [registerScreenQuery, setRegisterScreenQuery] = useState({
    SCREEN_NAME: "",
    SCREEN_PATH: "",
    SCREEN_NAME_ERROR: false,
    SCREEN_PATH_ERROR: false,
  });

  const [transactionFlags, setTransactionFlags] = useState({
    REGISTER_SCREEN_SAVE_LOADING: false,
    REGISTER_COMPONENT_SAVE_LOADING: false,
    CONNECT_SCREEN_COMPONENT_SAVE_LOADING: false,
  });

  useEffect(() => {
    getScreensList();
    getComponentsList();
    getConnectedScreenComponents();
  }, []);

  const screenListColumns = [
    {
      name: "Screen Name",
      selector: (val) => val.SCREEN_NAME,
      sortable: true,
    },
    {
      name: "Screen Path",
      selector: (val) => val.SCREEN_PATH,
      sortable: true,
    },
  ];
  const componentListColumns = [
    {
      name: "Component Name",
      selector: (val) => val.COMPONENT_NAME,
      sortable: true,
    },
    {
      name: "Component ID",
      selector: (val) => val.COMPONENT_ID,
      sortable: true,
    },
  ];
  const ScreenComponentListColumns = [
    {
      name: "Screen Name",
      selector: (val) => val.SCREEN_NAME,
      sortable: true,
    },
    {
      name: "Component Name",
      selector: (val) => val.COMPONENT_NAME,
      sortable: true,
    },
  ];

  const getScreensList = () => {
    fetch(AXIOS.defaultPort + AXIOS.screenGet, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // SCREEN_NAME: registerScreenQuery.SCREEN_NAME,
        // SCREEN_PATH: registerScreenQuery.SCREEN_PATH,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        let tempData = [];
        data.map((val) => {
          tempData.push({
            ...val,
            label: val.SCREEN_NAME,
            value: val.SCREEN_PATH,
          });
        });
        setScreenTbody(tempData);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };
  const getComponentsList = () => {
    fetch(AXIOS.defaultPort + AXIOS.componentGet, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // SCREEN_NAME: registerScreenQuery.SCREEN_NAME,
        // SCREEN_PATH: registerScreenQuery.SCREEN_PATH,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        let tempData = [];
        data.map((val) => {
          tempData.push({
            ...val,
            label: val.COMPONENT_NAME,
            value: val.COMPONENT_ID,
          });
        });
        setComponentTbody(tempData);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };
  const getConnectedScreenComponents = () => {
    fetch(AXIOS.defaultPort + AXIOS.screenComponentMapGet, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // SCREEN_NAME: registerScreenQuery.SCREEN_NAME,
        // SCREEN_PATH: registerScreenQuery.SCREEN_PATH,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        setScreenComponentTbody(data);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const registerScreens = () => {
    if (registerScreenQuery.SCREEN_NAME == "") {
      setRegisterScreenQuery((prev) => ({
        ...prev,
        SCREEN_NAME_ERROR: true,
      }));
    }
    if (registerScreenQuery.SCREEN_PATH == "") {
      setRegisterScreenQuery((prev) => ({
        ...prev,
        SCREEN_PATH_ERROR: true,
      }));
    }
    if (
      registerScreenQuery.SCREEN_NAME != "" &&
      registerScreenQuery.SCREEN_PATH != ""
    ) {
      setTransactionFlags((prev) => ({
        ...prev,
        REGISTER_SCREEN_SAVE_LOADING: true,
      }));
      fetch(AXIOS.defaultPort + AXIOS.screenCreateUpdate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          SCREEN_NAME: registerScreenQuery.SCREEN_NAME,
          SCREEN_PATH: registerScreenQuery.SCREEN_PATH,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          toast.success("Screen registered successfully");
          setRegisterScreenQuery({
            SCREEN_NAME: "",
            SCREEN_PATH: "",
            SCREEN_NAME_ERROR: false,
            SCREEN_PATH_ERROR: false,
          });
          getScreensList();
          setTransactionFlags((prev) => ({
            ...prev,
            REGISTER_SCREEN_SAVE_LOADING: false,
          }));
        })
        .catch((err) => {
          setTransactionFlags((prev) => ({
            ...prev,
            REGISTER_SCREEN_SAVE_LOADING: false,
          }));
          toast.error("Something went wrong");
        });
    }
  };
  const registerNewComponent = () => {
    if (registerComponentsQuery.COMPONENT_NAME == "") {
      setRegisterComponentsQuery((prev) => ({
        ...prev,
        COMPONENT_NAME_ERROR: true,
      }));
    }
    if (registerComponentsQuery.COMPONENT_ID == "") {
      setRegisterComponentsQuery((prev) => ({
        ...prev,
        COMPONENT_ID_ERROR: true,
      }));
    }
    if (
      registerScreenQuery.COMPONENT_NAME != "" &&
      registerScreenQuery.COMPONENT_ID != ""
    ) {
      setTransactionFlags((prev) => ({
        ...prev,
        REGISTER_COMPONENT_SAVE_LOADING: true,
      }));
      fetch(AXIOS.defaultPort + AXIOS.componentCreateUpdate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          COMPONENT_NAME: registerComponentsQuery.COMPONENT_NAME,
          COMPONENT_ID: registerComponentsQuery.COMPONENT_ID,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();


          toast.success("Component registered successfully");
          setRegisterComponentsQuery({
            COMPONENT_NAME: "",
            COMPONENT_ID: "",
            COMPONENT_NAME_ERROR: false,
            COMPONENT_ID_ERROR: false,
          });
          getComponentsList();
          setTransactionFlags((prev) => ({
            ...prev,
            REGISTER_COMPONENT_SAVE_LOADING: false,
          }));
        })
        .catch((err) => {
          setTransactionFlags((prev) => ({
            ...prev,
            REGISTER_COMPONENT_SAVE_LOADING: false,
          }));
          toast.error("Something went wrong");
        });
    }
  };
  const connectScreenToComponent = () => {
    if (mapScreenComponentsQuery.COMPONENT_NAME == "") {
      setRegisterComponentsQuery((prev) => ({
        ...prev,
        SCREEN_ID_ERROR: true,
      }));
    }
    if (mapScreenComponentsQuery.COMPONENT_ID == "") {
      setRegisterComponentsQuery((prev) => ({
        ...prev,
        COMPONENT_ID_ERROR: true,
      }));
    }
    if (
      mapScreenComponentsQuery.COMPONENT_NAME != "" &&
      mapScreenComponentsQuery.COMPONENT_ID != ""
    ) {
      setTransactionFlags((prev) => ({
        ...prev,
        CONNECT_SCREEN_COMPONENT_SAVE_LOADING: true,
      }));
      fetch(AXIOS.defaultPort + AXIOS.screenComponentMap, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          SCREEN_ID: mapScreenComponentsQuery.SCREEN_ID,
          COMPONENT_ID: mapScreenComponentsQuery.COMPONENT_ID,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();


          setMapScreenComponentsQuery({
            SCREEN_ID: "",
            COMPONENT_ID: "",
            SCREEN_ID_ERROR: false,
            COMPONENT_ID_ERROR: false,
          });

          toast.success("Screen & Component Connected Successfully");
          getConnectedScreenComponents();
          setTransactionFlags((prev) => ({
            ...prev,
            CONNECT_SCREEN_COMPONENT_SAVE_LOADING: false,
          }));
        })
        .catch((err) => {
          setTransactionFlags((prev) => ({
            ...prev,
            CONNECT_SCREEN_COMPONENT_SAVE_LOADING: false,
          }));
          toast.error("Something went wrong");
        });
    }
  };

  return (
    <MainScreen>
      <Toaster
        containerStyle={{
          top: 20,
          left: 20,
          bottom: 20,
          right: 20,
        }}
        gutter={24}
        toastOptions={{
          style: {
            border: "1px solid #grey",
            padding: "16px",
          },
          success: {
            style: {
              opacity: 0.1,
            },
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 3,
          paddingBottom: 1,
          paddingLeft: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              mr: 1,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <IconContext.Provider value={{ size: 25, color: "#000" }}>
              <FaArrowLeftLong />
            </IconContext.Provider>
          </Box>
          <Typography
            style={{
              fontSize: 22,
              fontWeight: 600,
              color: "#000",
              marginRight: 10,
              textAlign: "start",
            }}
          >
            Screen & Components
          </Typography>
        </Box>
      </Box>
      <Box className="role-container">
        {props.components_list.includes("view-screens") && (
          <Card className="box">
            <span
              style={{
                fontSize: 20,
                fontWeight: "bold",
                marginBottom: 10,
              }}
            >
              Create Screen
            </span>
            {props.components_list.includes("create-screens") && (
              <Box className="row role-basic-grid-container">
                <div className={"role-grid-item"}>
                  <CustomLabel LABEL={"Screen Name"} IS_REQUIRED={true} />
                  <CustomInput
                    Placeholder={"Subject"}
                    Style={{
                      height: 40,
                    }}
                    Value={registerScreenQuery.SCREEN_NAME}
                    onChange={(e) => {
                      setRegisterScreenQuery((prev) => ({
                        ...prev,
                        SCREEN_NAME: e.target.value,
                        SCREEN_NAME_ERROR: false,
                      }));
                    }}
                  />
                  {registerScreenQuery.SCREEN_NAME_ERROR && (
                    <span
                      style={{
                        color: "red",
                        fontSize: 14,
                      }}
                    >
                      Please enter Screen Name
                    </span>
                  )}
                </div>
                <div className={"role-grid-item"}>
                  <CustomLabel LABEL={"Screen Path"} IS_REQUIRED={true} />
                  <CustomInput
                    Placeholder={"Subject"}
                    Style={{
                      height: 40,
                    }}
                    Value={registerScreenQuery.SCREEN_PATH}
                    onChange={(e) => {
                      setRegisterScreenQuery((prev) => ({
                        ...prev,
                        SCREEN_PATH: e.target.value,
                        SCREEN_PATH_ERROR: false,
                      }));
                    }}
                  />
                  {registerScreenQuery.SCREEN_PATH_ERROR && (
                    <span
                      style={{
                        color: "red",
                        fontSize: 14,
                      }}
                    >
                      Please enter Screen Path
                    </span>
                  )}
                </div>
                <div className={"role-grid-item"}>
                  <p></p>
                  <br />
                  <button
                    className="filled-button"
                    style={{
                      marginTop: -5,
                      minWidth: 100,
                    }}
                    onClick={() => {
                      registerScreens();
                    }}
                    disabled={transactionFlags.REGISTER_SCREEN_SAVE_LOADING}
                  >
                    {transactionFlags.REGISTER_SCREEN_SAVE_LOADING == true ? (
                      <Spinner />
                    ) : (
                      "Register"
                    )}
                  </button>
                </div>
              </Box>
            )}

            <ReactDataTable columns={screenListColumns} data={screenTbody} />
          </Card>
        )}

        {props.components_list.includes("view-components") && (
          <Card className="box">
            <span
              style={{
                fontSize: 20,
                fontWeight: "bold",
                marginBottom: 10,
              }}
            >
              Create Components
            </span>
            {props.components_list.includes("create-components") && (
              <Box className="row role-basic-grid-container">
                <div className={"role-grid-item"}>
                  <CustomLabel LABEL={"Component Name"} IS_REQUIRED={true} />
                  <CustomInput
                    Placeholder={"Subject"}
                    Style={{
                      height: 40,
                    }}
                    Value={registerComponentsQuery.COMPONENT_NAME}
                    onChange={(e) => {
                      setRegisterComponentsQuery((prev) => ({
                        ...prev,
                        COMPONENT_NAME: e.target.value,
                      }));
                    }}
                  />
                  {registerComponentsQuery.COMPONENT_NAME_ERROR && (
                    <span
                      style={{
                        color: "red",
                        fontSize: 14,
                      }}
                    >
                      Please enter Component Name
                    </span>
                  )}
                </div>
                <div className={"role-grid-item"}>
                  <CustomLabel LABEL={"Component ID"} IS_REQUIRED={true} />
                  <CustomInput
                    Placeholder={"Subject"}
                    Style={{
                      height: 40,
                    }}
                    Value={registerComponentsQuery.COMPONENT_ID}
                    onChange={(e) => {
                      setRegisterComponentsQuery((prev) => ({
                        ...prev,
                        COMPONENT_ID: e.target.value,
                      }));
                    }}
                  />
                  {registerComponentsQuery.COMPONENT_ID_ERROR && (
                    <span
                      style={{
                        color: "red",
                        fontSize: 14,
                      }}
                    >
                      Please enter Component ID
                    </span>
                  )}
                </div>
                <div className={"role-grid-item"}>
                  <p></p>
                  <br />
                  <button
                    className="filled-button"
                    style={{
                      marginTop: -5,
                      minWidth: 100,
                    }}
                    disabled={transactionFlags.REGISTER_COMPONENT_SAVE_LOADING}
                    onClick={() => {
                      registerNewComponent();
                    }}
                  >
                    {transactionFlags.REGISTER_COMPONENT_SAVE_LOADING ==
                    true ? (
                      <Spinner />
                    ) : (
                      "Register"
                    )}
                  </button>
                </div>
              </Box>
            )}

            <ReactDataTable
              columns={componentListColumns}
              data={componentTbody}
            />
          </Card>
        )}

        {props.components_list.includes("map-screens-components") && (
          <Card className="box">
            <span
              style={{
                fontSize: 20,
                fontWeight: "bold",
                marginBottom: 10,
              }}
            >
              Map Screen & Component
            </span>
            {props.components_list.includes(
              "view-mapped-screens-components"
            ) && (
              <Box className="row role-basic-grid-container">
                <div className={"role-grid-item"}>
                  <CustomLabel LABEL={"Screen Name"} IS_REQUIRED={true} />
                  <CustomDropdown
                    Options={screenTbody}
                    menuPortalTarget={true}
                    placeholder={"Select Screen"}
                    height={"40px"}
                    Value={mapScreenComponentsQuery.SCREEN_ID}
                    OnChange={(e) => {
                      setMapScreenComponentsQuery((prev) => ({
                        ...prev,
                        SCREEN_ID: e,
                      }));
                    }}
                  />
                  {mapScreenComponentsQuery.SCREEN_ID_ERROR && (
                    <span
                      style={{
                        color: "red",
                        fontSize: 14,
                      }}
                    >
                      Please Select Screen Name
                    </span>
                  )}
                </div>
                <div className={"role-grid-item"}>
                  <CustomLabel LABEL={"Component Name"} IS_REQUIRED={true} />
                  <CustomDropdown
                    Options={componentTbody}
                    menuPortalTarget={true}
                    placeholder={"Select Component"}
                    height={"40px"}
                    Value={mapScreenComponentsQuery.COMPONENT_ID}
                    OnChange={(e) => {
                      setMapScreenComponentsQuery((prev) => ({
                        ...prev,
                        COMPONENT_ID: e,
                      }));
                    }}
                  />
                  {mapScreenComponentsQuery.COMPONENT_ID_ERROR && (
                    <span
                      style={{
                        color: "red",
                        fontSize: 14,
                      }}
                    >
                      Please Select Component Name
                    </span>
                  )}
                </div>
                <div className={"role-grid-item"}>
                  <p></p>
                  <br />
                  <button
                    className="filled-button"
                    style={{
                      marginTop: -20,
                      minWidth: 100,
                    }}
                    onClick={() => {
                      connectScreenToComponent();
                    }}
                    disabled={
                      transactionFlags.CONNECT_SCREEN_COMPONENT_SAVE_LOADING
                    }
                  >
                    {transactionFlags.CONNECT_SCREEN_COMPONENT_SAVE_LOADING ==
                    true ? (
                      <Spinner />
                    ) : (
                      "Connect"
                    )}
                  </button>
                </div>
              </Box>
            )}

            <ReactDataTable
              columns={ScreenComponentListColumns}
              data={ScreenComponentTbody}
            />
          </Card>
        )}
      </Box>
    </MainScreen>
  );
}

const mapStateToProps = (state) => ({
  components_list: state.contactData.components_list,
});

export default connect(mapStateToProps, {})(RegisterComponents);
