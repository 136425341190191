import React, { useEffect, useRef } from "react";
import $ from "jquery";
import "daterangepicker";
import moment from "moment";
import "./DateRangePicker.css";

const DateRangePicker = ({
  onSelectDateRange,
  defaultStartDate,
  defaultEndDate,
}) => {
  const datePickerRef = useRef(null);

  useEffect(() => {
    const datePicker = $(datePickerRef.current);

    // Initialize the date range picker
    datePicker.daterangepicker(
      {
        opens: "right",
        startDate: defaultStartDate ? moment(defaultStartDate) : undefined,
        endDate: defaultEndDate ? moment(defaultEndDate) : undefined,
        autoUpdateInput: false, // Prevents automatic value update on the input field
        locale: {
          format: "YYYY-MM-DD",
          applyLabel: "Apply",
          cancelLabel: "Cancel",
          customRangeLabel: "Custom Range",
        },
        ranges: {
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days"),
          ],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ],
        },
      },
      (start, end) => {
        // Callback invoked on date range selection
        const startDate = start.format("YYYY-MM-DD");
        const endDate = end.format("YYYY-MM-DD");
        datePicker.val(`${startDate} - ${endDate}`); // Update the input field
        onSelectDateRange(startDate, endDate); // Notify parent with selected values
      }
    );

    // If default dates are provided, manually update the input field
    if (defaultStartDate && defaultEndDate) {
      const formattedStartDate = moment(defaultStartDate).format("YYYY-MM-DD");
      const formattedEndDate = moment(defaultEndDate).format("YYYY-MM-DD");
      datePicker.val(`${formattedStartDate} - ${formattedEndDate}`);
    }

    // Handle placeholder and input field when defaultStartDate or defaultEndDate is empty
    if (!defaultStartDate && !defaultEndDate) {
      datePicker.val(""); // Clear the input field
    } else if (defaultStartDate && defaultEndDate) {
      const formattedStartDate = moment(defaultStartDate).format("YYYY-MM-DD");
      const formattedEndDate = moment(defaultEndDate).format("YYYY-MM-DD");
      datePicker.val(`${formattedStartDate} - ${formattedEndDate}`);
    }

    // Cleanup on component unmount
    return () => {
      if (datePicker.data("daterangepicker")) {
        datePicker.data("daterangepicker").remove();
      }
    };
  }, [defaultStartDate, defaultEndDate, onSelectDateRange]);

  return (
    <div className="group" style={{ backgroundColor: "#fdfdfe" }}>
      <input
        type="text"
        className="text-box-style"
        ref={datePickerRef}
        placeholder={
          !defaultStartDate && !defaultEndDate ? "Select Date Range" : ""
        }
      />
    </div>
  );
};

export default DateRangePicker;
