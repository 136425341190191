import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
export const engagementStatus = [
  { label: "Cold", value: "1", color: "#87CEEB" },
  { label: "Warm", value: "2", color: "#FFA500" },
  { label: "Hot", value: "3", color: "#AF1740" },
  { label: "Signed", value: "4", color: "#32CD32" },
  { label: "Dropped", value: "5", color: "#A9A9A9" },
];

export const meetingMode = [
  { label: "Email", value: "1" },
  { label: "Telephone", value: "2" },
  { label: "Virtual", value: "3" },
  { label: "In Person", value: "4" },
];
export const deliveryStatusArr = [
  {
    label: "Show All",
    value: 1,
  },
  {
    label: "Delivered",
    value: 2,
  },
  {
    label: "Not delivered yet",
    value: 3,
  },
  {
    label: "Bounced",
    value: 4,
  },
];

export const campaignStatus = [
  {
    label: "Edit",
    value: "1",
    color: "#1770E0",
    icon: <AppRegistrationIcon />,
  },
  {
    label: "Under Progress",
    value: "2",
    color: "#DDA853",
    icon: <PendingActionsIcon />,
  },
  {
    label: "Completed",
    value: "3",
    color: "#28a745",
    icon: <CheckCircleIcon />,
  },
];

export function formatDateToDDMMYYYY(isoDate) {
  const date = new Date(isoDate);

  // Extract day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = date.getFullYear();

  // Format the date as DD/MM/YYYY
  return `${day}/${month}/${year}`;
}
export const formatTime = (dateString) => {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const period = hours >= 12 ? "PM" : "AM";

  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes}${period}`;
};
// const mySalt = "gefdDhYU~GFCG^bfsem67xshah463ksd#$Iny316253)*#D";

// export const encodeData = (dataStr) => {
//   let data = dataStr.toString();
//   const textToChars = (data) => data.split("").map((c) => c.charCodeAt(0));
//   const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
//   const applySaltToChar = (code) =>
//     textToChars(mySalt).reduce((a, b) => a ^ b, code);
//   return data
//     .split("")
//     .map((c) => textToChars(c)[0])
//     .map(applySaltToChar)
//     .map(byteHex)
//     .join("");
// };
// export const decodeData = (data) => {
//   const textToChars = (data) => data.split("").map((c) => c.charCodeAt(0));
//   const applySaltToChar = (code) =>
//     textToChars(mySalt).reduce((a, b) => a ^ b, code);

//   return data
//     .match(/.{1,2}/g)
//     .map((hex) => parseInt(hex, 16))
//     .map(applySaltToChar)
//     .map((charCode) => String.fromCharCode(charCode))
//     .join("");
// };
const mySalt = "gefdDhYU~GFCG^bfsem67xshah463ksd#$Iny316253)*#D";

export const encodeData = (dataStr) => {
  let data = dataStr.toString();
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).slice(-2);
  const applySaltToChar = (code) =>
    textToChars(mySalt).reduce((a, b) => a ^ b, code);

  return data
    .split("")
    .map((c) => textToChars(c)[0]) // Convert character to ASCII
    .map(applySaltToChar) // XOR with salt
    .map(byteHex) // Convert to hex
    .join("");
};

export const decodeData = (encodedStr) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(mySalt).reduce((a, b) => a ^ b, code);

  return encodedStr
    .match(/.{1,2}/g) // Split into pairs of hex characters
    .map((hex) => parseInt(hex, 16)) // Convert hex to integer
    .map(applySaltToChar) // XOR again to retrieve original character
    .map((charCode) => String.fromCharCode(charCode)) // Convert ASCII back to character
    .join("");
};

// export const COMPANY_FREE_PERIOD_MONTH = 6;

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const NumberInputOnly = (inputString) => {
  let result = inputString.replace(/[^0-9]/g, "");
  return result;
};
export function isValidEmail(email) {
  // Regular expression for email validation
  let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
export function extractSubdomain(urlString) {
  // Parse the URL
  const parsedUrl = new URL(urlString);
  const parts = parsedUrl.hostname.split(".");
  const DotIndex = parsedUrl.hostname.indexOf(".");
  let SubDomain = -1;
  if (DotIndex != -1) {
    SubDomain = parts[0];
  }
  return SubDomain;
}
export function removeSpecialChars(str) {
  return str.replace(/[^a-zA-Z0-9 ]/g, "");
}
export const handleSearch = (value, setValue, stateCopy, setState) => {
  const tempData = stateCopy.filter((row) => {
    if (!value) return true; // No search query
    return Object.values(row)
      .filter((value) => typeof value === "string")
      .join(" ")
      .toLowerCase()
      .includes(value.toLowerCase());
  });

  setState(tempData);
  setValue(value);
};
