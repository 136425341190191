import React from "react";

function CustomLabel({ IS_REQUIRED,LABEL }) {
  return (
    <div
      className="flex"
      style={{
        alignItems: "center",
        display: "flex",
      }}
    >
      <span className="input-box-title mt-2">{LABEL}</span>
      {IS_REQUIRED==true && (
        <span
          style={{
            color: "red",
            fontSize: 12,
          }}
        >
          *
        </span>
      )}
    </div>
  );
}

export default CustomLabel;
