import React, { useState } from "react";
import MainScreen from "../../../components/Navbar/MainScreen";
import { Box, Card, Divider, Typography } from "@mui/material";
import { Toaster } from "react-hot-toast";
import CustomLabel from "../../../components/CustomLabel/CustomLabel";
import CustomInput from "../../../components/CustomInput/CustomInput";
import CustomDropdown from "../../../components/CustomDropDown/CustomDropDown";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

import {
  capitalizeFirstLetter,
  NumberInputOnly,
} from "../../../utils/Constants";
import "../../margins.css";
import "./EmployeeOnboarding.css";
import { IconContext } from "react-icons";
import { useNavigate } from "react-router-dom";

function EmployeeOnboarding() {
  const navigate = useNavigate();
  const tempEmployeeDetails = [
    {
      TITLE: "Contact Number",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Alternative Number",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Date Of Birth",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Education Qualification",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Father/Husband Name",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Father/Husband Number",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Current Address",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Permanent Address",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Mother/Wife Name",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Mother/Wife Number",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "City",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Religion",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Gender",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
  ];

  const tempEmployeeAccessSetup = [
    {
      TITLE: "Report To",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Role",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Position",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Date Of Joining",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Username/Email",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Employee Type",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Salary",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Business Email",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "PF A/c",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Work from Home",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Leave Bucket",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Personal Email",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
  ];

  const tempBankDetails = [
    {
      TITLE: "Bank Account Number",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Bank Name",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "IFSC Code",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "UPI ID",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
  ];
  const tempDocumentCenter = [
    {
      TITLE: "Eduction Document",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Insurance Document",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Pan Card",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Aadhar Card",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "PF Document",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Health Insurance",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
    {
      TITLE: "Other Documents",
      DROPDOWN: false,
      INPUT_TYPE: "text",
      KEY: "BUILDING_NAME",
      FIRST_CAPITAL: true,
      VALUE: "",
      INFO: "",
      IS_REQUIRED: true,
      ERROR_FLAG: false,
      CLASS_NAME: "col-3 ml-2",
    },
  ];
  const [employeeBasicDetails, setEmployeeBasicDetails] =
    useState(tempEmployeeDetails);
  const [employeeAccessSetup, setEmployeeAccessSetup] = useState(
    tempEmployeeAccessSetup
  );
  const [bankDetails, setBankDetails] = useState(tempBankDetails);
  const [documentCenter, setDocumentCenter] = useState(tempDocumentCenter);

  const handleInputChange = (value, index) => {
    let tempSingUpFields = [...employeeBasicDetails];
    let TempValue;
    if (tempSingUpFields[index].INPUT_TYPE == "number") {
      TempValue = NumberInputOnly(value);
    } else if (tempSingUpFields[index].FIRST_CAPITAL) {
      TempValue = capitalizeFirstLetter(value);
    } else {
      TempValue = value;
    }
    tempSingUpFields[index].VALUE = TempValue;
    tempSingUpFields[index].ERROR_FLAG = false;
    setEmployeeBasicDetails(tempSingUpFields);
  };
  return (
    <MainScreen className="dashboard-main-container">
      <Toaster
        containerStyle={{
          top: 20,
          left: 20,
          bottom: 20,
          right: 20,
        }}
        gutter={24}
        toastOptions={{
          style: {
            border: "1px solid #grey",
            padding: "16px",
          },
          success: {
            style: {
              opacity: 0.1,
            },
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 3,
          paddingBottom: 1,
          paddingLeft: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              mr: 1,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <IconContext.Provider value={{ size: 25, color: "#000" }}>
              <FaArrowLeftLong />
            </IconContext.Provider>
          </Box>
          {/* <h9 className="header-text">Contact Capture</h9> */}
          <Typography
            style={{
              fontSize: 22,
              fontWeight: 600,
              color: "#000",
              marginRight: 10,
              textAlign: "start",
            }}
          >
            Employee Onboarding
          </Typography>
        </Box>
      </Box>

      <Card
        sx={{
          m: 1,
          p: 1,
        }}
      >
        <span
          style={{
            fontSize: 20,
            fontWeight: "bold",
            marginBottom: 10,
          }}
        >
          Basic Details
        </span>
        <Divider />
        <Box className="row employee-basic-grid-container">
          {employeeBasicDetails.map((val, index) => {
            return (
              <div className={"employee-grid-item"}>
                <CustomLabel LABEL={val.TITLE} IS_REQUIRED={val.IS_REQUIRED} />
                {val.DROPDOWN == false && (
                  <CustomInput
                    Placeholder={val.PLACEHOLDER}
                    required={val.IS_REQUIRED}
                    onChange={(e) => {
                      handleInputChange(e.target.value, index);
                    }}
                    placeholder={val.PLACEHOLDER}
                    value={val.VALUE}
                    errorFlag={val.ERROR_FLAG}
                    errorMessage={
                      val.ERROR_FLAG ? `Please fill ${val.TITLE}` : ""
                    }
                  />
                )}
                {val.DROPDOWN == true && (
                  <CustomDropdown
                    isSearchable={false}
                    isMulti={true}
                    menuPlacement={"top"}
                    // Options={allCampaignList}
                    // Value={fromAnotherCampaignQuery.SELECTED_CAMPAIGNS}
                    // OnChange={(e) => {
                    //   console.log("Adajsndjasdas", e);
                    //   setFromAnotherCampaignQuery((prev) => ({
                    //     ...prev,
                    //     SELECTED_CAMPAIGNS: e,
                    //   }));
                    // }}
                  />
                )}
              </div>
            );
          })}
        </Box>
        <span
          style={{
            fontSize: 20,
            fontWeight: "bold",
            marginBottom: 10,
          }}
        >
          Access Setup
        </span>
        <Divider />
        <Box className="row employee-basic-grid-container">
          {employeeAccessSetup.map((val, index) => {
            return (
              <div className={"employee-grid-item"}>
                <CustomLabel LABEL={val.TITLE} IS_REQUIRED={val.IS_REQUIRED} />
                {val.DROPDOWN == false && (
                  <CustomInput
                    Placeholder={val.PLACEHOLDER}
                    required={val.IS_REQUIRED}
                    onChange={(e) => {
                      handleInputChange(e.target.value, index);
                    }}
                    placeholder={val.PLACEHOLDER}
                    value={val.VALUE}
                    errorFlag={val.ERROR_FLAG}
                    errorMessage={
                      val.ERROR_FLAG ? `Please fill ${val.TITLE}` : ""
                    }
                  />
                )}
                {val.DROPDOWN == true && (
                  <CustomDropdown
                    isSearchable={false}
                    isMulti={true}
                    menuPlacement={"top"}
                    // Options={allCampaignList}
                    // Value={fromAnotherCampaignQuery.SELECTED_CAMPAIGNS}
                    // OnChange={(e) => {
                    //   console.log("Adajsndjasdas", e);
                    //   setFromAnotherCampaignQuery((prev) => ({
                    //     ...prev,
                    //     SELECTED_CAMPAIGNS: e,
                    //   }));
                    // }}
                  />
                )}
              </div>
            );
          })}
        </Box>
        <span
          style={{
            fontSize: 20,
            fontWeight: "bold",
            marginBottom: 10,
          }}
        >
          Bank Details
        </span>
        <Divider />
        <Box className="row employee-basic-grid-container">
          {bankDetails.map((val, index) => {
            return (
              <div className={"employee-grid-item"}>
                <CustomLabel LABEL={val.TITLE} IS_REQUIRED={val.IS_REQUIRED} />
                {val.DROPDOWN == false && (
                  <CustomInput
                    Placeholder={val.PLACEHOLDER}
                    required={val.IS_REQUIRED}
                    onChange={(e) => {
                      handleInputChange(e.target.value, index);
                    }}
                    placeholder={val.PLACEHOLDER}
                    value={val.VALUE}
                    errorFlag={val.ERROR_FLAG}
                    errorMessage={
                      val.ERROR_FLAG ? `Please fill ${val.TITLE}` : ""
                    }
                  />
                )}
                {val.DROPDOWN == true && (
                  <CustomDropdown
                    isSearchable={false}
                    isMulti={true}
                    menuPlacement={"top"}
                    // Options={allCampaignList}
                    // Value={fromAnotherCampaignQuery.SELECTED_CAMPAIGNS}
                    // OnChange={(e) => {
                    //   console.log("Adajsndjasdas", e);
                    //   setFromAnotherCampaignQuery((prev) => ({
                    //     ...prev,
                    //     SELECTED_CAMPAIGNS: e,
                    //   }));
                    // }}
                  />
                )}
              </div>
            );
          })}
        </Box>
        <span
          style={{
            fontSize: 20,
            fontWeight: "bold",
            marginBottom: 10,
          }}
        >
          Document Center
        </span>
        <Divider />
        <Box className="row employee-basic-grid-container">
          {documentCenter.map((val, index) => {
            return (
              <div className={"employee-grid-item"}>
                <CustomLabel LABEL={val.TITLE} IS_REQUIRED={val.IS_REQUIRED} />
                {val.DROPDOWN == false && (
                  <CustomInput
                    Placeholder={val.PLACEHOLDER}
                    required={val.IS_REQUIRED}
                    onChange={(e) => {
                      handleInputChange(e.target.value, index);
                    }}
                    placeholder={val.PLACEHOLDER}
                    value={val.VALUE}
                    errorFlag={val.ERROR_FLAG}
                    errorMessage={
                      val.ERROR_FLAG ? `Please fill ${val.TITLE}` : ""
                    }
                  />
                )}
                {val.DROPDOWN == true && (
                  <CustomDropdown
                    isSearchable={false}
                    isMulti={true}
                    menuPlacement={"top"}
                    // Options={allCampaignList}
                    // Value={fromAnotherCampaignQuery.SELECTED_CAMPAIGNS}
                    // OnChange={(e) => {
                    //   console.log("Adajsndjasdas", e);
                    //   setFromAnotherCampaignQuery((prev) => ({
                    //     ...prev,
                    //     SELECTED_CAMPAIGNS: e,
                    //   }));
                    // }}
                  />
                )}
              </div>
            );
          })}
        </Box>
      </Card>
    </MainScreen>
  );
}

export default EmployeeOnboarding;
