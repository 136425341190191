import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
// import Select from "@mui/material/Select";

import Select from "react-select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Box } from "@mui/material";
import { COLORS } from "../../constants/theme";
function CustomDropdown({
  Options,
  Value,
  OnChange,
  Label,
  error,
  menuPlacement,
  placeholder,
  isMulti,
  height,
  menuPortalTarget,
}) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  // Handle window resizing and set mobile view state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let webDropdownHeight = height || "30px";
  return (
    <Box>
      <Select
        classNames={{
          control: (state) => (state.isFocused ? "text-error" : "text-error"),
        }}
        className="text-dropdown-style text-error"
        options={Options}
        isMulti={isMulti}
        // value={Value.label!=null ? Value :null}
        value={
          Array.isArray(Value)
            ? Value.length > 0
              ? Value
              : null // If Value is an array, check its length
            : typeof Value === "object" && Value !== null && Value.label != null
              ? Value // If Value is an object, check for label property
              : null // Default to null if none of the conditions are met
        }
        onChange={OnChange}
        placeholder={placeholder}
        menuPortalTarget={menuPortalTarget ? document.body : null}
        menuPlacement={menuPlacement || "auto"}
        // menuPortalTarget={document.body}
        // placeholder={"Select " + Label}
        styles={{
          placeholder: (defaultStyles) => {
            return {
              ...defaultStyles,
              color: "#94a3b8",
              fontSize: 13,
              display: "flex",
              alignItems: "center",
              // padding: 2,
              height: isMobile ? "40px" : webDropdownHeight,
              // padding: "0 0px",
              // fontWeight: 450,
              // marginTop: -5,
            };
          },
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isSelected ? "#70777d" : "white",
            color: state.isSelected ? "white" : "black",
            fontSize: 12,
            display: "flex",
            alignItems: "center",
            padding: 2,
            // marginTop: -5,
            paddingLeft: 5,
            height: 25,
            ":hover": {
              backgroundColor: "#1770E0",
              color: "white",
            },
          }),
          control: (provided, state) => ({
            ...provided,
            background: "#fff",
            borderColor: isMobile ? "#b3b3b3" : "#E0E0E0",
            height: isMobile ? "40px" : webDropdownHeight,
            minHeight: isMobile ? "40px" : webDropdownHeight,
            borderRadius: 5,
            background: "#fdfdfe",
            boxShadow: state.isFocused ? "0 0 5px #f9fbfd" : "",
            ":hover": {
              borderColor: isMobile ? "#b3b3b3" : "#E0E0E0",
              cursor: "text",
            },
          }),

          valueContainer: (provided, state) => ({
            ...provided,
            // height: "30px",
            height: isMobile ? "40px" : webDropdownHeight,
            padding: "0 6px",
          }),

          input: (provided, state) => ({
            ...provided,
            margin: "0px",
          }),
          indicatorSeparator: (state) => ({
            display: "none",
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            height: isMobile ? "40px" : webDropdownHeight,
          }),
          menuPortal: (provided) => ({
            ...provided,
            // zIndex: 9999, // Ensure the menu appears above other elements
          }),
        }}
      />
    </Box>
  );
}

export default CustomDropdown;
