import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import toast, { Toaster } from "react-hot-toast";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Divider,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import CustomDropdown from "../components/CustomDropDown/CustomDropDown";
import DateRangePicker from "../components/CustomInput/DateRangePicker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./ActivityStatus.css";
import ReactDataTable from "../components/ReactDataTable/ReactDataTable";
import ExcelIcon from "../assets/icons/excel-icon.svg";
import AXIOS from "../utils/AXIOS";
import axios from "../components/Axios/axios";
import { connect } from "react-redux";
import { engagementStatus, meetingMode } from "../utils/Constants";
import EastIcon from "@mui/icons-material/East";
import RightArrow from "../assets/icons/right-arrow.png";
import Spinner from "../components/Spinner/Spinner";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { saveAs } from "file-saver";

import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import MainScreen from "../components/Navbar/MainScreen";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels
);

function ActivityStatus(props) {
  const navigate = useNavigate();

  const CrManagerWiseWise = useRef();
  const ContactManagedByCRRef = useRef();
  const leadEngagementRef = useRef();

  const [OwnMeetingsData, setOwnMeetingsData] = useState([]);
  const [CrManagersMeetingData, setCrManagersMeetingData] = useState([]);

  const [crManagersList, setCrManagerList] = useState([]);
  const [contactList, setContactList] = useState([]);

  const [CrManagerWiseQuery, setCrManagerWiseQuery] = useState({
    startDate: "",
    endDate: "",
    CR_MANAGER: { label: "All CRMs", value: "", _id: "" },
  });
  const [leadEngagementStatusQuery, setLeadEngagementStatusQuery] = useState({
    startDate: "",
    endDate: "",
  });
  const [leadEngagementStatusTbody, setLeadEngagementStatusTbody] = useState({
    COLD: [],
    WARM: [],
    HOT: [],
    SIGNED: [],
    DROPPED: [],
  });

  // const [userRoles, setUserRoles] = useState([]);

  // useEffect(() => {
  //   if (props.employee_data) {
  //     if (
  //       props.employee_data.filter((val) => val.email_id == props.auth_email_id)
  //         .length > 0
  //     ) {
  //       let role = props.employee_data.find(
  //         (val) => val.email_id == props.auth_email_id
  //       ).role;
  //       let tempRole = [];
  //       tempRole.push(role);

  //       setUserRoles(tempRole);

  //       // if (role == "admin") {
  //       //   setAdminAccess(true);
  //       //   setViewAccess(true);
  //       // }
  //       // if (role == "view") {
  //       //   setViewAccess(false);
  //       // }
  //     }
  //   }
  // }, [props.employee_data]);

  useEffect(() => {
    if (props.auth_id) {
      getEmployeeListByAuthID();
    }
  }, [props.auth_id]);

  const getEmployeeListByAuthID = () => {
    axios
      .post(AXIOS.defaultPort + AXIOS.roleWiseEmpGet, {
        AUTH_ID: props.auth_id,
      })
      .then((response) => {
        let temp = [...response.value];
        temp.map((val) => {
          val.label = val.employee_name;
          val.value = val.ID;
        });

        setCrManagerList(temp);
      })
      .catch((err) => {});
  };

  const [CrManagerWiseTBody, setCrManagerWiseTBodyWiseTbody] = useState({
    COLD: [],
    WARM: [],
    HOT: [],
    SIGNED: [],
    DROPPED: [],
  });
  const [ContactManagedByCRTbody, setContactManagedByCRTbody] = useState({
    COUNT: [],
    LABELS: [],
  });

  const [CrManagersMeetingQuery, setCrManagersMeetingQuery] = useState({
    CR_MANAGER: { label: "Select...", value: "", _id: "" },
    CONTACT_ID: { label: "Select...", value: "", _id: "" },
    startDate: new Date(),
    endDate: new Date(),
  });
  const [ScheduledMeetingQuery, setScheduledMeetingQuery] = useState({
    CR_MANAGER: { label: "Select...", value: "", _id: "" },
    CONTACT_ID: { label: "Select...", value: "", _id: "" },
    startDate: new Date(),
    endDate: new Date(),
  });

  const downloadChart = (ref) => {
    const chart = ref.current; // Get the chart instance
    if (chart) {
      const url = chart.toBase64Image(); // Convert the chart to a Base64 image
      const link = document.createElement("a");
      link.href = url;
      link.download = "chart.png"; // Set the download filename
      link.click(); // Trigger the download
    }
  };

  useEffect(() => {
    if (props.auth_id) {
      getOwnMeetingData(false, props.auth_id);
      setScheduledMeetingQuery((prev) => ({
        ...prev,
        CR_MANAGER: {
          label: props.auth_name,
          value: props.auth_email_id,
          _id: props.auth_id,
        },
      }));
    }
  }, [props.auth_id, props.auth_name]);

  useEffect(() => {
    if (props.auth_id) {
      getLeadStatusCrManagerData();
    }
  }, [CrManagerWiseQuery, props.auth_id]);
  useEffect(() => {
    if (props.auth_id) {
      getLeadStatusData();
    }
  }, [leadEngagementStatusQuery, props.auth_id]);

  useEffect(() => {
    if (props.contact_data) {
      let tempContactManagedByCR = [];

      props.employee_data.map((val) => {
        tempContactManagedByCR.push({
          crManager: val._id,
          crManagerName: val.employee_name,
          contactCount: 0,
        });
      });


      props.contact_data.map((val) => {
        let index = tempContactManagedByCR.findIndex(
          (item) => item.crManager == val.crManager
        );

        if (index == -1) {
          tempContactManagedByCR.push({
            crManager: val.crManager,
            crManagerName: val.crManagerName,
            contactCount: 1,
          });
        } else {
          tempContactManagedByCR[index].contactCount += 1;
        }
      });

      const crManagerNames = tempContactManagedByCR.map(
        (item) => item.crManagerName || 0
      );
      const contactCounts = tempContactManagedByCR.map(
        (item) => item.contactCount || 0
      );
      setContactManagedByCRTbody({
        COUNT: contactCounts,
        LABELS: crManagerNames,
      });
    }
  }, [props.contact_data, props.employee_data]);

  const getLeadStatusCrManagerData = () => {
    axios
      .post(AXIOS.defaultPort + AXIOS.LeadStatusCrManagerWise, {
        START_DATE: convertIndianStandardIntoYMD(CrManagerWiseQuery.startDate),
        END_DATE: convertIndianStandardIntoYMD(CrManagerWiseQuery.endDate),
        CR_MANAGER: CrManagerWiseQuery.CR_MANAGER._id,
        AUTH_ID: props.auth_id,
      })
      .then((response) => {
        if (response) {
          let tempCold = [];
          let tempWarm = [];
          let tempHot = [];
          let tempSigned = [];
          let tempDropped = [];
          let tempCrManagers = [];
          response.map((val) => {
            tempCold.push(val["1"]);
            tempWarm.push(val["2"]);
            tempHot.push(val["3"]);
            tempSigned.push(val["4"]);
            tempDropped.push(val["5"]);
            tempCrManagers.push(val.employee_name);
          });

          setCrManagerWiseTBodyWiseTbody({
            COLD: tempCold,
            WARM: tempWarm,
            HOT: tempHot,
            SIGNED: tempSigned,
            DROPPED: tempDropped,
            LABELS: tempCrManagers,
          });
        }
      })
      .catch((err) => {});
  };
  const getLeadStatusData = () => {
    axios
      .post(AXIOS.defaultPort + AXIOS.LeadStatusCrManagerWise, {
        START_DATE: convertIndianStandardIntoYMD(
          leadEngagementStatusQuery.startDate
        ),
        END_DATE: convertIndianStandardIntoYMD(
          leadEngagementStatusQuery.endDate
        ),
        CR_MANAGER: "",
        AUTH_ID: props.auth_id,
      })
      .then((response) => {
        if (response) {

          let tempCold = [];
          let tempWarm = [];
          let tempHot = [];
          let tempSigned = [];
          let tempDropped = [];
          let tempCrManagers = [];
          response.map((val) => {
            tempCold.push(val["1"]);
            tempWarm.push(val["2"]);
            tempHot.push(val["3"]);
            tempSigned.push(val["4"]);
            tempDropped.push(val["5"]);
            tempCrManagers.push(val.employee_name);
          });

          setLeadEngagementStatusTbody({
            COLD: tempCold,
            WARM: tempWarm,
            HOT: tempHot,
            SIGNED: tempSigned,
            DROPPED: tempDropped,
            LABELS: tempCrManagers,
          });
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    // setCrManagerList(props.employee_data);

    let tempContactData = [];
    if (props.contact_data) {
      props.contact_data.map((val) => {
        tempContactData.push({
          label: val.name0,
          value: val.ID,
          ...val,
        });
      });
    }

    setContactList(tempContactData);
  }, [props.employee_data, props.contact_data]);

  const convertIndianStandardIntoYMD = (date) => {
    var dateObj = new Date(date);
    if (!isNaN(dateObj) && dateObj != "") {
      let mnth = ("0" + (dateObj?.getMonth() + 1)).slice(-2);
      let day = ("0" + dateObj?.getDate())?.slice(-2);
      return [dateObj.getFullYear(), mnth, day].join("-");
    }
    return "";
  };

  const [TransactionHistory, setTransactionHistory] = useState(false);
  const [TransactionScheduled, setTransactionScheduled] = useState(false);
  const getOwnMeetingData = (ShowToast, auth_id) => {
    axios
      .post(AXIOS.defaultPort + AXIOS.meetingFilter, {
        START_DATE: convertIndianStandardIntoYMD(
          ScheduledMeetingQuery.startDate
        ),
        END_DATE: convertIndianStandardIntoYMD(ScheduledMeetingQuery.endDate),
        CONTACT_ID: ScheduledMeetingQuery.CONTACT_ID.value,
        CR_MANAGER: auth_id || ScheduledMeetingQuery.CR_MANAGER._id,
      })
      .then((response) => {
        setTransactionScheduled(false);
        if (response) {
          if (response.length > 0) {
          } else {
            if (ShowToast) {
              toast.error("No data found for selected filter");
            }
          }
          setOwnMeetingsData(response);
        }
      })
      .catch((err) => {
        setTransactionScheduled(false);
        toast.error("Something went wrong");
      });
  };

  const getCrsMeetingData = () => {
    setTransactionHistory(true);

    axios
      .post(AXIOS.defaultPort + AXIOS.getMeetingUpdate, {
        START_DATE: convertIndianStandardIntoYMD(
          CrManagersMeetingQuery.startDate
        ),
        END_DATE: convertIndianStandardIntoYMD(CrManagersMeetingQuery.endDate),
        CONTACT_ID: CrManagersMeetingQuery.CONTACT_ID.value,
        CR_MANAGER: CrManagersMeetingQuery.CR_MANAGER._id,
      })
      .then((response) => {
        setTransactionHistory(false);
        if (response) {
          if (response.length > 0) {
            setCrManagersMeetingData(response);
          } else {
            toast.error("No data found for selected filter");
          }
        }
      })
      .catch((err) => {
        setTransactionHistory(false);

        toast.error("Something went wrong");
      });
  };

  const MeetingDetailsHeader = [
    {
      name: "Edit By",
      selector: (val) => val.editedByName,
      sortable: false,
    },

    {
      name: "Meeting By",
      selector: (val) => val.meetingByName,
      sortable: false,
      cell: (val, index) => (
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {val.meetingByName}

          {val.meetingByName != val.prevMeetingByName && (
            <>
              <span
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                <IconContext.Provider
                  value={{
                    size: 15,
                    color: "#000",
                  }}
                >
                  <FaArrowRightLong />
                </IconContext.Provider>
              </span>

              <span> {val.prevMeetingByName} </span>
            </>
          )}
        </span>
      ),
    },
    {
      name: "Meeting With",
      selector: (val) => val.contactName,
      sortable: false,
    },

    {
      name: "Company Name",
      selector: (val) => val.company,
      sortable: false,
    },
    {
      name: "Meeting Date",
      selector: (val) => val.meetingTime,
      sortable: false,
      // cell: (val, index) => (
      //   <span>{new Date(val.meetingDate).toDateString()}</span>
      // ),
      cell: (val, index) => (
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {new Date(val.meetingTime).toDateString()}

          {val.meetingTime != val.prevMeetingTime && (
            <>
              <span
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                <IconContext.Provider
                  value={{
                    size: 15,
                    color: "#000",
                  }}
                >
                  <FaArrowRightLong />
                </IconContext.Provider>
              </span>

              <span> {new Date(val.prevMeetingTime).toDateString()} </span>
            </>
          )}
        </span>
      ),
    },
    {
      name: "Meeting Time",
      selector: (val) => val.meetingTime,
      sortable: false,
      // cell: (val, index) => (
      //   <span>
      //     {new Date(val.meetingTime).toLocaleString("en-US", {
      //       hour: "numeric",
      //       minute: "numeric",
      //       hour12: true,
      //     })}
      //   </span>
      // ),
      cell: (val, index) => (
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {new Date(val.meetingTime).toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}

          {val.meetingTime != val.prevMeetingTime && (
            <>
              <span
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                <IconContext.Provider
                  value={{
                    size: 15,
                    color: "#000",
                  }}
                >
                  <FaArrowRightLong />
                </IconContext.Provider>
              </span>
              <span>
                {new Date(val.prevMeetingTime).toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}{" "}
              </span>
            </>
          )}
        </span>
      ),
    },
    {
      name: "Remarks",
      selector: (val) => val.remarks,
      sortable: false,
      cell: (val, index) => (
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {val.remarks}

          {val.remarks != val.prevRemarks && (
            <>
              <span
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                <IconContext.Provider
                  value={{
                    size: 15,
                    color: "#000",
                  }}
                >
                  <FaArrowRightLong />
                </IconContext.Provider>
              </span>

              <span> {val.prevRemarks} </span>
            </>
          )}
        </span>
      ),
    },

    {
      name: "Mode of Meeting",
      selector: (val) => val.meetingMode,
      sortable: false,
      // cell: (val, index) => (
      //   <span>
      //     {meetingMode.find((item) => item.value == val.meetingMode)?.label ||
      //       ""}
      //   </span>
      // ),
      cell: (val, index) => (
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {meetingMode.find((item) => item.value == val.meetingMode)?.label ||
            ""}
          {val.meetingMode != val.prevMeetingMode && (
            <>
              <span
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                <IconContext.Provider
                  value={{
                    size: 15,
                    color: "#000",
                  }}
                >
                  <FaArrowRightLong />
                </IconContext.Provider>
              </span>

              <span>
                {" "}
                {meetingMode.find((item) => item.value == val.meetingMode)
                  ?.label || ""}{" "}
              </span>
            </>
          )}
        </span>
      ),
    },

    {
      name: "Stage of engagement",
      selector: (val) => val.engagementStatus,
      sortable: false,
      // cell: (val, index) => (
      //   <span>
      //     {engagementStatus.find((item) => item.value == val.engagementStatus)
      //       ?.label || ""}
      //   </span>
      // ),
      cell: (val, index) => (
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {engagementStatus.find((item) => item.value == val.meetingMode)
            ?.label || ""}
          {val.engagementStatus != val.prevEngagementStatus && (
            <>
              <span
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                }}
              >
                <IconContext.Provider
                  value={{
                    size: 15,
                    color: "#000",
                  }}
                >
                  <FaArrowRightLong />
                </IconContext.Provider>
              </span>

              <span>
                {engagementStatus.find(
                  (item) => item.value == val.prevEngagementStatus
                )?.label || ""}{" "}
              </span>
            </>
          )}
        </span>
      ),
    },
    {
      name: "Updated At",
      selector: (val) => val.updatedAt,
      sortable: false,
      cell: (val, index) => (
        <span>
          {new Date(val.updatedAt).toDateString()}{" "}
          {new Date(val.updatedAt).toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}{" "}
        </span>
      ),
    },
  ];
  const OwnMeetingColumns = [
    {
      name: "Company Name",
      selector: (val) => val.company,
      sortable: false,
    },
    {
      name: "Contact Name",
      selector: (val) => val.contactName,
      sortable: false,
    },
    {
      name: "Meeting Date",
      selector: (val) => val.meetingTime,
      sortable: false,
      cell: (val, index) => (
        <span>{new Date(val.meetingTime).toDateString()}</span>
      ),
    },
    {
      name: "Meeting Time",
      selector: (val) => val.meetingTime,
      sortable: false,
      cell: (val, index) => (
        <span>
          {new Date(val.meetingTime).toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </span>
      ),
    },
    {
      name: "Remarks",
      selector: (val) => val.remarks,
      sortable: false,
    },
    {
      name: "Mode of meeting",
      selector: (val) => val.meetingMode,
      sortable: false,

      cell: (val, index) => (
        <span>
          {" "}
          {meetingMode.find((item) => item.value == val.meetingMode)?.label ||
            ""}{" "}
        </span>
      ),
    },
    {
      name: "Stage of engagement",
      selector: (val) => val.engagementStatus,
      sortable: false,
      cell: (val, index) => (
        <span>
          {engagementStatus.find((item) => item.value == val.engagementStatus)
            ?.label || ""}
        </span>
      ),
    },
  ];

  const CsvHeader = [
    {
      name: "Company",
      selector: "company",
    },
    {
      name: "Contact",
      selector: "contactName",
    },
    {
      name: "Meeting Date",
      selector: "meetingDate",
    },

    {
      name: "Meeting time",
      selector: "meetingTime",
    },
    {
      name: "Remarks",
      selector: "remarks",
    },
    {
      name: "Mode of Meeting",
      selector: "meetingMode",
    },
    {
      name: "Stage of engagement",
      selector: "engagementStatus",
    },
  ];
  const handleOwnMeetingExport = () => {
    let tempData = [];

    let excelData = [...OwnMeetingsData];

    if (excelData.length > 0) {
      // Exclude the "Action" column from csvColumns
      const csvColumns = CsvHeader.filter(
        (column) => column.name !== "Action"
      ).map((column) => column.name);

      const csvRows = excelData.map((item) =>
        csvColumns.map((columnName) => {
          const column = CsvHeader.find((col) => col.name === columnName);
          if (column) {
            let cellValue = "";
            if (typeof column.name === "function") {
              cellValue = column.selector(item);
            } else {
              if (column.name == "Meeting Date") {
                cellValue = new Date(item[column.selector]).toDateString();
              } else if (column.selector == "meetingTime") {
                cellValue = new Date(item[column.selector]).toLocaleString(
                  "en-US",
                  {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  }
                );
              } else if (column.selector == "meetingMode") {
                cellValue =
                  meetingMode.find((val) => val.value == item[column.selector])
                    ?.label || "";
              } else if (column.name == "Stage of engagement") {
                cellValue =
                  engagementStatus.find(
                    (val) => val.value == item[column.selector]
                  )?.label || "";
              } else {
                cellValue = item[column.selector] || "";
              }
            }
            // Wrap cell value in double quotes to handle commas
            return `"${cellValue}"`;
          }
          return ""; // Return an empty value for excluded columns
        })
      );
      const csvContent =
        csvColumns.join(",") +
        "\n" +
        csvRows.map((row) => row.join(",")).join("\n");

      const blob = new Blob([csvContent], {
        type: "text/csv;charset=utf-8",
      });
      saveAs(
        blob,
        ScheduledMeetingQuery.CR_MANAGER.label + "'s Upcoming Meetings.csv"
      ); // Use the saveAs function to download the CSV file
    } else {
    }
  };
  return (
    <MainScreen>
      <Box className="dashboard-main-container">
        <div>
          <Toaster
            containerStyle={{
              top: 20,
              left: 20,
              bottom: 20,
              right: 20,
            }}
            gutter={24}
            toastOptions={{
              style: {
                border: "1px solid #grey",
                padding: "16px",
              },
              success: {
                style: {
                  opacity: 0.1,
                },
              },
            }}
          />
        </div>
        {/* <Navbar headText="ACTIVITY_STATUS" /> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: 3,
            paddingBottom: 1,
            paddingLeft: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                mr: 1,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <IconContext.Provider value={{ size: 25, color: "#000" }}>
                <FaArrowLeftLong />
              </IconContext.Provider>
            </Box>
            {/* <h9 className="header-text">Contact Capture</h9> */}
            <Typography
              style={{
                fontSize: 22,
                fontWeight: 600,
                color: "#000",
                marginRight: 10,
                textAlign: "start",
              }}
            >
              Activity Status
            </Typography>
          </Box>
        </Box>

        {props.components_list.includes("upcoming-meetings") && (
          <Card sx={{ minHeight: "50vh", m: 1, p: 1 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <Typography
                style={{
                  fontSize: 18,
                  color: "#000",

                  textAlign: "start",
                  fontWeight: "bold",
                }}
              >
                Upcoming Meetings
              </Typography>

              {props.components_list.includes("meetings-exports") && (
                <button
                  data-label="Register"
                  className="rainbow-hover"
                  onClick={() => {
                    handleOwnMeetingExport();
                  }}
                  style={{
                    backgroundColor: "#23a466",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={ExcelIcon} style={{ width: 25, height: 25 }} />
                  <span className="sp">Export</span>
                </button>
              )}
            </div>

            <Box className="as-inner-container">
              {props.components_list.includes("view-other-cr-data") ? (
                <>
                  <div className="fields-width">
                    <Typography
                      style={{
                        fontSize: 18,
                        color: "#000",
                        textAlign: "start",
                      }}
                    >
                      CR Manager
                    </Typography>
                    <CustomDropdown
                      Options={crManagersList}
                      Value={ScheduledMeetingQuery.CR_MANAGER}
                      OnChange={(e) => {
                        setScheduledMeetingQuery((prev) => ({
                          ...prev,
                          CR_MANAGER: e,
                        }));
                      }}
                    />
                  </div>
                  <div className="fields-width">
                    <Typography
                      style={{
                        fontSize: 18,
                        color: "#000",
                        textAlign: "start",
                      }}
                    >
                      Contact
                    </Typography>
                    <CustomDropdown
                      Options={contactList}
                      Value={ScheduledMeetingQuery.CONTACT_ID}
                      OnChange={(e) => {
                        setScheduledMeetingQuery((prev) => ({
                          ...prev,
                          CONTACT_ID: e,
                        }));
                      }}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}

              <div
                style={{
                  marginLeft: props.components_list.includes(
                    "view-other-cr-data"
                  )
                    ? null
                    : 10,
                }}
                className="fields-width"
              >
                <Typography
                  style={{
                    fontSize: 18,
                    color: "#000",
                    textAlign: "start",
                  }}
                >
                  Date Range
                </Typography>
                <DateRangePicker
                  defaultStartDate={ScheduledMeetingQuery.startDate}
                  defaultEndDate={ScheduledMeetingQuery.endDate}
                  onSelectDateRange={(startDate, endDate) => {
                    setScheduledMeetingQuery((prev) => ({
                      ...prev,
                      startDate: startDate,
                      endDate: endDate,
                    }));
                  }}
                />
              </div>
              <div>
                <button
                  style={{
                    marginRight: 10,
                    minWidth: 150,
                    minHeight: 34,
                    maxHeight: 34,
                    marginTop: 25,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    getOwnMeetingData(true);
                  }}
                  className="signup-button"
                >
                  {TransactionScheduled ? <Spinner /> : "View"}
                </button>
              </div>
            </Box>

            <ReactDataTable
              columns={OwnMeetingColumns}
              data={OwnMeetingsData}
            />
          </Card>
        )}

        {props.components_list.includes("meetings-update-history") && (
          <Card
            sx={{
              p: 1,
              m: 1,
              minHeight: "50vh",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  fontSize: 18,
                  color: "#000",
                  fontWeight: "bold",
                  textAlign: "start",
                }}
              >
                Meetings Update History
              </Typography>
            </div>

            <Box className="as-inner-container">
              <div className="fields-width">
                <Typography
                  style={{
                    fontSize: 18,
                    color: "#000",
                    textAlign: "start",
                  }}
                >
                  CR Manager
                </Typography>
                <CustomDropdown
                  Options={crManagersList}
                  Value={CrManagersMeetingQuery.CR_MANAGER}
                  OnChange={(e) => {
                    setCrManagersMeetingQuery((prev) => ({
                      ...prev,
                      CR_MANAGER: e,
                    }));
                  }}
                />
              </div>
              <div className="fields-width">
                <Typography
                  style={{
                    fontSize: 18,
                    color: "#000",
                    textAlign: "start",
                  }}
                >
                  Contact
                </Typography>
                <CustomDropdown
                  Options={contactList}
                  Value={CrManagersMeetingQuery.CONTACT_ID}
                  OnChange={(e) => {
                    setCrManagersMeetingQuery((prev) => ({
                      ...prev,
                      CONTACT_ID: e,
                    }));
                  }}
                />
              </div>

              <div className="fields-width">
                <Typography
                  style={{
                    fontSize: 18,
                    color: "#000",
                    textAlign: "start",
                  }}
                >
                  Date Range
                </Typography>
                <DateRangePicker
                  defaultStartDate={CrManagersMeetingQuery.startDate}
                  defaultEndDate={CrManagersMeetingQuery.endDate}
                  onSelectDateRange={(startDate, endDate) => {
                    setCrManagersMeetingQuery((prev) => ({
                      ...prev,
                      startDate: startDate,
                      endDate: endDate,
                    }));
                  }}
                />
              </div>
              <div>
                <button
                  style={{
                    marginRight: 10,
                    minWidth: 150,
                    minHeight: 34,
                    maxHeight: 34,
                    marginTop: 25,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    getCrsMeetingData();
                  }}
                  className="signup-button"
                >
                  {TransactionHistory ? <Spinner /> : "View"}
                </button>
              </div>
            </Box>
            <Box
              sx={{
                p: 1,
              }}
              className="as-accordion-container"
            >
              <ReactDataTable
                columns={MeetingDetailsHeader}
                data={CrManagersMeetingData}
              />
            </Box>
          </Card>
        )}
        {props.components_list.includes(
          "engagement-status-cr-manager-wise"
        ) && (
          <Card
            sx={{
              p: 1,
              m: 1,
            }}
          >
            <Typography
              style={{
                fontSize: 18,
                color: "#000",
                // margin: 10,
                fontWeight: "bold",
                textAlign: "start",
              }}
            >
              Engagement Status CR Manager Wise
            </Typography>
            <Box className="as-inner-container">
              <div className="fields-width">
                <Typography
                  style={{
                    fontSize: 18,
                    color: "#000",
                    textAlign: "start",
                  }}
                >
                  CR Manager
                </Typography>
                <CustomDropdown
                  Options={[
                    { label: "All CRMs", value: "", _id: "" },
                    ...crManagersList,
                  ]}
                  Value={CrManagerWiseQuery.CR_MANAGER}
                  OnChange={(e) => {
                    setCrManagerWiseQuery((prev) => ({
                      ...prev,
                      CR_MANAGER: e,
                    }));
                  }}
                />
              </div>

              <div className="fields-width">
                <Typography
                  style={{
                    fontSize: 18,
                    color: "#000",
                    textAlign: "start",
                  }}
                >
                  Date Range
                </Typography>
                <DateRangePicker
                  defaultStartDate={CrManagerWiseQuery.startDate}
                  defaultEndDate={CrManagerWiseQuery.endDate}
                  onSelectDateRange={(startDate, endDate) => {
                    setCrManagerWiseQuery((prev) => ({
                      ...prev,
                      startDate: startDate,
                      endDate: endDate,
                    }));
                  }}
                />
              </div>
              <div />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {props.components_list.includes("graph-download") && (
                  <button
                    style={{
                      marginRight: 10,
                      minWidth: 150,
                      minHeight: 34,
                      maxHeight: 34,
                      marginTop: 25,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      downloadChart(CrManagerWiseWise);
                    }}
                    className="signup-button"
                  >
                    Download
                  </button>
                )}
              </div>
            </Box>

            <Bar
              ref={CrManagerWiseWise}
              data={{
                labels: CrManagerWiseTBody.LABELS,
                datasets: [
                  {
                    label: "Cold",
                    backgroundColor: "#87CEEB",
                    borderWidth: 1,
                    hoverBackgroundColor: "#87CEEB",
                    hoverBorderColor: "#87CEEB",
                    data: CrManagerWiseTBody.COLD,
                  },
                  {
                    label: "Warm",
                    backgroundColor: "#FFA500",
                    borderWidth: 1,
                    hoverBackgroundColor: "#FFA500",
                    hoverBorderColor: "#FFA500",
                    data: CrManagerWiseTBody.WARM,
                  },
                  {
                    label: "Hot",
                    backgroundColor: "#AF1740",
                    borderWidth: 1,
                    hoverBackgroundColor: "#AF1740",
                    hoverBorderColor: "#AF1740",
                    data: CrManagerWiseTBody.HOT,
                  },
                  {
                    label: "Signed",
                    backgroundColor: "#32CD32",
                    borderWidth: 1,
                    hoverBackgroundColor: "#32CD32",
                    hoverBorderColor: "#32CD32",
                    data: CrManagerWiseTBody.SIGNED,
                  },
                  {
                    label: "Dropped",
                    backgroundColor: "#A9A9A9",
                    borderWidth: 1,
                    hoverBackgroundColor: "#A9A9A9",
                    hoverBorderColor: "#A9A9A9",
                    data: CrManagerWiseTBody.DROPPED,
                  },
                ],
              }}
              options={{
                plugins: {
                  datalabels: {
                    anchor: (context) => {
                      return context.dataset.data[[context.dataIndex]] === 0
                        ? "top"
                        : "center";
                    },
                    align: (context) => {
                      return context.dataset.data[[context.dataIndex]] === 0
                        ? "top"
                        : "center";
                    },
                    color: "#000", // Text color
                    font: {
                      weight: "bold",
                    },
                    formatter: (value) => value, // Display the value as is
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
            />
          </Card>
        )}

        {props.components_list.includes("contacts-managed-by-cr-managers") && (
          <Card
            sx={{
              p: 1,
              m: 1,
              // pt: 2,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // margin: 10,
              }}
            >
              <Typography
                style={{
                  fontSize: 18,
                  color: "#000",

                  fontWeight: "bold",
                  textAlign: "start",
                }}
              >
                Contacts Managed By CR Managers
              </Typography>

              {props.components_list.includes("graph-download") && (
                <button
                  onClick={() => {
                    downloadChart(ContactManagedByCRRef);
                  }}
                  style={{
                    marginRight: 10,
                    minWidth: 150,
                    minHeight: 34,
                    maxHeight: 34,
                    // marginTop: 25,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="signup-button"
                >
                  Download
                </button>
              )}
            </div>
            <Bar
              ref={ContactManagedByCRRef}
              data={{
                labels: ContactManagedByCRTbody.LABELS,
                datasets: [
                  {
                    label: "Contacts",
                    backgroundColor: ["#87CEEB", "#FFA500", "#32CD32"],
                    borderWidth: 1,
                    hoverBackgroundColor: ["#87CEEB", "#FFA500", "#32CD32"],
                    hoverBorderColor: ["#87CEEB", "#FFA500", "#32CD32"],
                    data: ContactManagedByCRTbody.COUNT,
                  },
                ],
              }}
              options={{
                plugins: {
                  datalabels: {
                    anchor: (context) => {
                      return context.dataset.data[[context.dataIndex]] === 0
                        ? "top"
                        : "center";
                    },
                    align: (context) => {
                      return context.dataset.data[[context.dataIndex]] === 0
                        ? "top"
                        : "center";
                    },
                    color: "#000", // Text color
                    font: {
                      weight: "bold",
                    },
                    formatter: (value) => value, // Display the value as is
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
            />
          </Card>
        )}
        {props.components_list.includes("lead-engagement-status") && (
          <Card
            sx={{
              p: 1,
              m: 1,
              pb: 10,
            }}
            className="chart-container"
          >
            <span
              style={{
                fontSize: 18,
                color: "#000",
                // margin: 10,
                fontWeight: "bold",
                textAlign: "start",
              }}
            >
              Lead Engagement Status
            </span>
            <Box className="as-inner-container">
              <div
                className="fields-width"
                style={{
                  marginRight: 10,
                }}
              >
                <DateRangePicker
                  defaultStartDate={leadEngagementStatusQuery.startDate}
                  defaultEndDate={leadEngagementStatusQuery.endDate}
                  onSelectDateRange={(startDate, endDate) => {
                    setLeadEngagementStatusQuery((prev) => ({
                      ...prev,
                      startDate: startDate,
                      endDate: endDate,
                    }));
                  }}
                />
              </div>
              <div />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  style={{
                    marginRight: 10,
                    minWidth: 150,
                    minHeight: 34,
                    maxHeight: 34,
                    // marginTop: 25,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    setLeadEngagementStatusQuery((prev) => ({
                      ...prev,
                      startDate: "",
                      endDate: "",
                    }));
                  }}
                  className="import-button"
                >
                  Show All Data
                </button>
              </div>

              {props.components_list.includes("graph-download") && (
                <button
                  style={{
                    marginRight: 10,
                    minWidth: 150,
                    minHeight: 34,
                    maxHeight: 34,
                    // marginTop: 25,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    downloadChart(leadEngagementRef);
                  }}
                  className="signup-button"
                >
                  Download
                </button>
              )}
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingBottom: 10,
              }}
            ></div>
            <Doughnut
              ref={leadEngagementRef}
              data={{
                labels: ["Cold", "Warm", "Hot", "Signed", "Dropped"],
                datasets: [
                  {
                    label: "CR Manager Status",
                    data: [
                      leadEngagementStatusTbody.COLD.reduce((a, b) => a + b, 0),
                      leadEngagementStatusTbody.WARM.reduce((a, b) => a + b, 0),
                      leadEngagementStatusTbody.HOT.reduce((a, b) => a + b, 0),
                      leadEngagementStatusTbody.SIGNED.reduce(
                        (a, b) => a + b,
                        0
                      ),
                      leadEngagementStatusTbody.DROPPED.reduce(
                        (a, b) => a + b,
                        0
                      ),
                    ],
                    backgroundColor: [
                      "#87CEEB", // Cold
                      "#FFA500", // Warm
                      "#AF1740", // Hot
                      "#32CD32", // Signed
                      "#A9A9A9", // Dropped
                    ],
                    borderWidth: 1,
                    hoverBackgroundColor: [
                      "#87CEEB",
                      "#FFA500",
                      "#AF1740",
                      "#32CD32",
                      "#A9A9A9",
                    ],
                    hoverBorderColor: [
                      "#87CEEB",
                      "#FFA500",
                      "#AF1740",
                      "#32CD32",
                      "#A9A9A9",
                    ],
                  },
                ],
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: "right",
                    labels: {
                      font: {
                        size: 14,
                      },
                      padding: 20,
                    },
                  },
                  datalabels: {
                    color: "#000",
                    font: {
                      weight: "bold",
                      size: 12,
                    },
                    formatter: (value) => value,
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        let label = context.label || "";
                        let value = context.raw || 0;
                        return `${label}: ${value}`;
                      },
                    },
                  },
                },
                cutout: "50%", // Adjusts the size of the center cutout for a doughnut chart
              }}
            />
          </Card>
        )}
      </Box>
    </MainScreen>
  );
}

const mapStateToProps = (state) => ({
  auth_email_id: state.contactData.auth_email_id,
  auth_name: state.contactData.auth_name,
  auth_id: state.contactData.auth_id,
  contact_data: state.contactData.contactDataList,
  employee_data: state.contactData.employeeList,
  role: state.contactData.role,
  components_list: state.contactData.components_list,
});

export default connect(mapStateToProps, {})(ActivityStatus);
