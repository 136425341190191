import logo from "./logo.svg";
import { Box } from "@mui/material";
import "./App.css";
import { Brow } from "react-router-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom/dist";
import ContactCapture from "./screens/ContactCapture";
import ScanText from "./screens/ScanText";
import SearchPage from "./screens/SearchPage";
import ContactDetails from "./screens/ContactDetails";
import CompanyView from "./screens/CompanyView";
import Dashboard from "./screens/Dashboard";
import SendEmail from "./screens/SendEmail";
import MailHistory from "./screens/MailHistory";
import AdminPanel from "./screens/AdminPanel";
import Page404 from "./screens/Page404";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { ContactDataGetAction } from "./Redux/Action/ContactDataGetAction";
import { MeetingDataGetAction } from "./Redux/Action/MeetingDataGetAction";
import { EmployeeDetailsGetAction } from "./Redux/Action/EmployeeDetailsGetAction";
import Loader from "./screens/Loader";
import Lottie from "react-lottie-player";
import loadingAnime from "./assets/lottieFiles/loader.json";
import Login from "./screens/Login";
import axios from "./components/Axios/axios";
import { AxiosDefaultPort } from "./constants/Axios";
import ProtectedRoute from "./ProtectedRoute";
import ActivityStatus from "./screens/ActivityStatus";
import EmployeeHierarchy from "./screens/EmployeeHierarchy";
import MainScreen from "./components/Navbar/MainScreen";
import Campaigns from "./screens/BulkEmail/Campaigns";
import ViewCampaigns from "./screens/BulkEmail/ViewCampaigns";
import CampaignDetail from "./screens/BulkEmail/CampaignDetail";
import EmailTemplateEdit from "./screens/BulkEmail/EmailTemplateEdit";
import EmailTemplates from "./screens/BulkEmail/EmailTemplates";
import Testing from "./screens/BulkEmail/Testing";
import EmployeeOnboarding from "./screens/HRMS/EmployeeOnboarding/EmployeeOnboarding";
import RegisterComponents from "./screens/RBAC/RegisterComponents";
import ManageRoles from "./screens/RBAC/ManageRoles";
import AXIOS from "./utils/AXIOS";
import { decodeData, encodeData } from "./utils/Constants";
function App(props) {
  const [empExist, setEmpExist] = useState("NODATA");
  // const [empExist, setEmpExist] = useState(null);
  const [role, setRole] = useState("");
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const SessionToken = window.localStorage.getItem("CMS_STOKEN");
    if (SessionToken) {
      handleSessionVerify(SessionToken);
    }
  }, []);

  const handleSessionVerify = (SessionID) => {
    axios
      .post(AxiosDefaultPort + "apis/Login/sessionTokenVerify", {
        SESSION_TOKEN: SessionID,
      })
      .then((response) => {
        if (response.otpMatch == true) {
          if (!Array.isArray(response.sessionData.role)) {
            window.localStorage.removeItem("CMS_STOKEN");
          } else {
            dispatch({
              type: "AUTH_DATA",
              auth_name: response.sessionData.NAME,
              auth_email_id: response.sessionData.EMAIL_ID,
              user_token: response.sessionData.SESSION_TOKEN,
              auth_id: response.sessionData.ID,
              role: response.sessionData.role,
            });
            getRolesDetails(response.sessionData.ID);
          }
        } else {
          window.localStorage.removeItem("CMS_STOKEN");
        }
      });
  };

  const routes = [
    { path: "/dashboard", element: <Dashboard />, exact: true },
    { path: "/activity-status", element: <ActivityStatus />, exact: true },
    { path: "/contactCapture", element: <ContactCapture />, exact: true },
    { path: "/searchPage", element: <SearchPage />, exact: true },
    { path: "/contactDetails", element: <ContactDetails />, exact: true },
    { path: "/companyView", element: <CompanyView />, exact: true },
    { path: "/sendEmail", element: <SendEmail />, exact: true },
    {
      path: "/employee-hierarchy",
      element: <EmployeeHierarchy />,
      exact: true,
    },
    { path: "/add-campaign", element: <Campaigns />, exact: true },
    { path: "/view-campaign", element: <ViewCampaigns />, exact: true },
    { path: "/campaign-details", element: <CampaignDetail />, exact: true },
    {
      path: "/email-template-edit",
      element: <EmailTemplateEdit />,
      exact: true,
    },
    { path: "/email-templates", element: <EmailTemplates />, exact: true },
    {
      path: "/employee-onboarding",
      element: <EmployeeOnboarding />,
      exact: true,
    },
    { path: "/email-templates", element: <EmailTemplates />, exact: true },
    {
      path: "/register-component",
      element: <RegisterComponents />,
      exact: true,
    },
    {
      path: "/manage-roles",
      element: <ManageRoles />,
      exact: true,
    },
    {
      path: "/mailHistory",
      element: <MailHistory />,
      exact: true,
    },
    {
      path: "/admin",
      element: <AdminPanel />,
      exact: true,
    },
  ];
  const [AssessableScreens, setAssessableScreens] = useState([]);

  const getRolesDetails = (EmpId) => {
    fetch(AXIOS.defaultPort + AXIOS.rolesGetByEmpId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        EMPLOYEE_ID: EmpId,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log("asdjasdasdas", data);

        dispatch({
          type: "ROLE_DATA",
          components_list: data.COMPONENTS,
          screen_list: data.SCREENS,
          flow_type: data.FLOW_TYPE,
        });

        sessionStorage.setItem(
          "BetaCode/591286274::[[v.2]]",
          encodeData(JSON.stringify(data))
        );
      })
      .catch((err) => {
        console.log("asdkjandasdas", err);
      });
  };

  useEffect(() => {
    let ScreensData = sessionStorage.getItem("BetaCode/591286274::[[v.2]]");
    if (ScreensData) {
      ScreensData = decodeData(ScreensData);
      ScreensData = JSON.parse(ScreensData);
      const filteredRoutes =
        ScreensData.SCREENS &&
        ScreensData.SCREENS.map((data) => {
          const foundRoute = routes.find((route) => route.path === "/" + data);
          return foundRoute ? { ...foundRoute } : null;
        }).filter(Boolean);

      setAssessableScreens(filteredRoutes);
      setLoading(false);
    } else {
      sessionStorage.removeItem("BetaCode/591286274::[[v.2]]");
    }
  }, [props.components_list]);

  return (
    // <BrowserRouter>
    <>
      {/* <MainScreen /> */}
      <Routes>
        {/* <Route path="/" element={<Dashboard />} /> */}
        <Route path="/" element={<Login />} />
        {AssessableScreens.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<ProtectedRoute>{route.element}</ProtectedRoute>} // Use curly braces to include route.element
              exact={route.exact}
            />
          );
        })}
        {/* <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/activity-status"
          element={
            <ProtectedRoute>
              <ActivityStatus />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contactCapture"
          element={
            <ProtectedRoute>
              <ContactCapture />
            </ProtectedRoute>
          }
        />
        <Route
          path="/scanText"
          element={
            <ProtectedRoute>
              <ScanText />
            </ProtectedRoute>
          }
        />
        <Route
          path="/searchPage"
          element={
            <ProtectedRoute>
              <SearchPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contactDetails"
          element={
            <ProtectedRoute>
              <ContactDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/companyView"
          element={
            <ProtectedRoute>
              <CompanyView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sendEmail"
          element={
            <ProtectedRoute>
              <SendEmail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employee-hierarchy"
          element={
            <ProtectedRoute>
              <EmployeeHierarchy />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-campaign"
          element={
            <ProtectedRoute>
              <Campaigns />
            </ProtectedRoute>
          }
        />
        <Route
          path="/view-campaign"
          element={
            // <ProtectedRoute>
            <ViewCampaigns />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/campaign-details"
          element={
            <ProtectedRoute>
              <CampaignDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/email-template-edit"
          element={
            <ProtectedRoute>
              <EmailTemplateEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/email-templates"
          element={
            <ProtectedRoute>
              <EmailTemplates />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employee-onboarding"
          element={
            <ProtectedRoute>
              <EmployeeOnboarding />
            </ProtectedRoute>
          }
        />
        <Route
          path="/register-component"
          element={
            <ProtectedRoute>
              <RegisterComponents />
            </ProtectedRoute>
          }
        />
        <Route
          path="/manage-roles"
          element={
            <ProtectedRoute>
              <ManageRoles />
            </ProtectedRoute>
          }
        />
        <Route
          path="/testing"
          element={
            <ProtectedRoute>
              <Testing />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <AdminPanel />
            </ProtectedRoute>
          }
        /> */}
        {loading == false && <Route path="*" element={<Page404 />} />}
        {/* <Route path="/mailHistory" element={<MailHistory />} /> */}
        {/* {props?.role &&
          props?.role.length > 0 &&
          (props?.role.includes("admin") || props?.role.includes("1")) && (
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <AdminPanel />
                </ProtectedRoute>
              }
            />
          )} */}
        {/* {props?.role &&
          props?.role.length > 0 &&
          !props.role.includes("admin") &&
          !props.role.includes("1") && (
            <Route path="/admin" element={<Page404 />} />
          )} */}

        {/* {empExist == false && (
          <>
            <Route path="/" element={<Page404 />} />
            <Route path="/contactCapture" element={<Page404 />} />
            <Route path="/scanText" element={<Page404 />} />
            <Route path="/searchPage" element={<Page404 />} />
            <Route path="/contactDetails" element={<Page404 />} />
            <Route path="/companyView" element={<Page404 />} />
            <Route path="/sendEmail" element={<Page404 />} />
            <Route path="/mailHistory" element={<Page404 />} />
            <Route path="/admin" element={<Page404 />} />
          </>
        )} */}
      </Routes>
      {/* {empExist == "NODATA" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
            minHeight:"100vh",
            
          }}
        >
          <Lottie
            // className="loading-lottie"
            style={{
              width: 250,
              height: 250,
            }}
            play
            //   speed={"1"}
            animationData={loadingAnime}
          />
        </Box>
      )} */}
    </>
    // </BrowserRouter>
  );
}

// export default App;
const mapStateToProps = (state) => ({
  contact_data: state.contactData.contactDataList,
  auth_email_id: state.contactData.auth_email_id,
  auth_name: state.contactData.auth_name,
  employee_data: state.contactData.employeeList,
  role: state.contactData.role,
  components_list: state.contactData.components_list,
});

export default connect(mapStateToProps, {
  ContactDataGetAction,
  MeetingDataGetAction,
  EmployeeDetailsGetAction,
})(App);
