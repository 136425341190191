import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import {
  Box,
  Card,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  Table,
  TableRow,
  Paper,
  TableBody,
  TextField,
  Tooltip,
  Checkbox,
  Divider,
  Modal,
} from "@mui/material";

import { Link, useNavigate, useLocation } from "react-router-dom";
import Images from "../assets/images/Images";
import Icons from "../constants/Icons";
// import * as microsoftTeams from "@microsoft/teams-js";
import { connect, useDispatch } from "react-redux";
import { COLORS } from "../constants/theme";
import Page404 from "./Page404";
import Lottie from "react-lottie-player";
import loadingAnime from "../assets/lottieFiles/loader.json";
import NoDataFoundAnime from "../assets/lottieFiles/no-data-found.json";
import { EmployeeDetailsGetAction } from "../Redux/Action/EmployeeDetailsGetAction";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import welcomeGreetings from "../assets/images/welcome-greeting.svg";
import ReactDataTable from "../components/ReactDataTable/ReactDataTable";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DateRangePicker from "../components/CustomInput/DateRangePicker";
import AXIOS from "../utils/AXIOS";
import CustomDropdown from "../components/CustomDropDown/CustomDropDown";
import toast, { Toaster } from "react-hot-toast";
import CustomButton from "../components/CustomButton/CustomButton";
import { IconContext } from "react-icons";
import { RxCross1 } from "react-icons/rx";
import { engagementStatus } from "../utils/Constants";
import MainScreen from "../components/Navbar/MainScreen";

function Dashboard(props) {
  const navigate = useNavigate();
  const [viewAccess, setViewAccess] = useState(false);
  const [empExist, setEmpExist] = useState("NODATA");
  const [role, setRole] = useState("");
  const dispatch = useDispatch();
  const [Tbody, setTbody] = useState([]);
  const [TbodyLoading, setTbodyLoading] = useState(true);

  const [dashboardQuery, setDashboardQuery] = useState({
    START_DATE: "",
    END_DATE: "",
  });

  const [DeleteConfirmContactModal, setDeleteConfirmContactModal] =
    useState(false);
  const [CRManagerQuery, setCRManagerQuery] = useState({
    CR_MANAGER: {
      label: null,
      value: null,
    },
    ERROR_FLAG: false,
  });

  const [selectedContacts, setSelectedContacts] = useState({
    ids: [], // stores selected contact IDs
  });

  const updateBulkCRManagers = () => {
    if (CRManagerQuery.CR_MANAGER.value != null) {
      fetch(AXIOS.defaultPort + AXIOS.crmBulkUpdate, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          crManager: CRManagerQuery.CR_MANAGER._id,
          contactIds: selectedContacts.ids,
          updatedBy: props.auth_id,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setSelectedContacts({
            ids: [],
          });
          toast.success("CR Manager updated");

          getContactData();
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    } else {
      toast.error("Please select CR Manager");
      setCRManagerQuery((prev) => ({
        ...prev,
        ERROR_FLAG: true,
      }));
    }
  };

  const deleteBulkContacts = () => {
    if (selectedContacts.ids.length > 0) {
      fetch(AXIOS.defaultPort + AXIOS.contactBulkDelete, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          contactIds: selectedContacts.ids,
          updatedBy: props.auth_id,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();

          toast.success("Contacts Deleted");
          setDeleteConfirmContactModal(false);
          setSelectedContacts({
            ids: [],
          });
          getContactData();
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    } else {
      toast.error("Please select contact");
    }
  };

  useEffect(() => {
    props
      .EmployeeDetailsGetAction()
      .then((res) => {})
      .catch((err) => {
        setEmpExist("ECONNREF");
      });
  }, []);

  useEffect(() => {
    if (props.employee_data.length > 0) {
      if (
        props.employee_data.some((val) => val.email_id == props.auth_email_id)
      ) {
        setEmpExist(true);
        let empRole = props.employee_data.find(
          (val) => val.email_id == props.auth_email_id
        ).role;

        setRole(empRole);
      } else {
        setEmpExist(false);
      }
    }
  }, [props.employee_data]);

  useEffect(() => {
    if (props.employee_data) {
      if (
        props.employee_data.filter((val) => val.email_id == props.auth_email_id)
          .length > 0
      ) {
        let role = props.employee_data.find(
          (val) => val.email_id == props.auth_email_id
        ).role;

        if (role == "view") {
          setViewAccess(true);
        }
      }
    }
  }, [props.employee_data]);

  const textStyle = {
    fontSize: 22,
    fontWeight: "bold",
    color: COLORS.gray70,
    mt: 2,
  };

  const [userDetails, setUserDetails] = useState(null);

  function formatDateToDDMMYYYY(isoDate) {
    const date = new Date(isoDate);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    // Format the date as DD/MM/YYYY
    return `${day}/${month}/${year}`;
  }
  const getUserDetails = async (token) => {
    // Decode JWT to extract the payload
    const base64Url = token.split(".")[1]; // Get the payload part of the token
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/"); // Normalize base64 string
    const payload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const decodedToken = JSON.parse(payload);

    // Extract 'oid' from the decoded token
    const oid = decodedToken.oid;
    const upn = decodedToken.upn;
    // Instead of fetching user details, directly set OID and token in state
    setUserDetails({
      OBJECT_ID: oid,
      token: token, // Store the token directly
      EMAIL_ID: upn,
    });
  };

  const [authName, setAuthName] = useState("");
  useEffect(() => {
    setAuthName(props.auth_name);
  }, [props.auth_name]);

  const isAllSelected = selectedContacts.ids.length === Tbody.length;
  const handleCheckboxChange = (_id) => {
    setSelectedContacts((prevState) => {
      const { ids } = prevState;

      // If ID is already selected, remove it from the state
      if (ids.includes(_id)) {
        return {
          ids: ids.filter((contactId) => contactId !== _id),
        };
      }

      const contact = Tbody.find((contact) => contact._id === _id);
      return {
        ids: [...ids, _id],
      };
    });
  };

  // Handle "Select All" checkbox
  const handleSelectAll = () => {
    setSelectedContacts((prevState) => {
      const { ids } = prevState;
      const isAllSelected = ids.length === Tbody.length;

      // If all are selected, deselect all
      if (isAllSelected) {
        return { ids: [] };
      }

      // Otherwise, select all
      const allIds = Tbody.map((contact) => contact._id);
      return {
        ids: allIds,
      };
    });
  };

  const ContactColumns = [
    {
      name: (
        <input
          type="checkbox"
          checked={isAllSelected}
          onChange={() => {
            handleSelectAll();
          }}
        />
      ),
      selector: (row) => row._id,
      width: "50px",
      cell: (row) => (
        <input
          type="checkbox"
          checked={selectedContacts.ids.includes(row._id)}
          onChange={() => handleCheckboxChange(row._id)}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "View",
      selector: (val) => val.company,
      sortable: false,
      width: "60px",
      cell: (val) => {
        return (
          <span>
            <RemoveRedEyeIcon
              sx={{
                color: "#1770E0",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/contactDetails", {
                  state: {
                    searchContact: val,
                  },
                });
              }}
            />
          </span>
        );
      },
    },
    {
      name: "Contact Name",
      width: "180px",
      selector: (val) => val.name0,
      sortable: true,
      cell: (val) => {
        return (
          <span
            style={{
              color: "#1770E0",
              cursor: "pointer",
              textAlign: "start",
            }}
            onClick={() => {
              navigate("/contactDetails", {
                state: {
                  searchContact: val,
                },
              });
            }}
          >
            {val.name0}
          </span>
        );
      },
    },
    {
      name: "Designation",
      width: "200px",
      selector: (val) => val.designation,
      sortable: true,
    },
    {
      name: "Email",
      width: "200px",
      selector: (val) => val.email,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (val) => val.mobile,
      sortable: true,
    },
    {
      name: "Company",
      selector: (val) => val.company,
      sortable: true,
      width: "200px",
      cell: (val) => {
        return (
          <span
            style={{
              color: "#1770E0",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/companyView", {
                state: {
                  companyName: val.company,
                },
              });
            }}
          >
            {val.company}
          </span>
        );
      },
    },
    {
      name: "CR Manager",
      width: "200px",
      selector: (val) => val.crManagerName,
      sortable: true,
    },
    {
      name: "Engagement Status",
      width: "160px",
      selector: (val) => val.engagementStatus,
      sortable: true,
      cell: (val) => {
        let engagementData = engagementStatus.find(
          (item) => item.value == val.engagementStatus
        );

        return (
          <span
            style={{
              backgroundColor: engagementData?.color,
              padding: 5,
              borderRadius: 5,
              color: "#fff",
            }}
          >
            {engagementData?.label}
          </span>
        );
      },
    },
    {
      name: "Address",
      selector: (val) => val.address,
      sortable: true,
      width: "200px",
    },
    {
      name: "City",
      selector: (val) => val.city,
      sortable: true,
    },
    {
      name: "Department",
      selector: (val) => val.department,
      sortable: true,
    },
    {
      name: "Industry",
      selector: (val) => val.industry,
      sortable: true,
    },
    {
      name: "Level",
      selector: (val) => val.level,
      sortable: true,
    },

    {
      name: "Created By",
      selector: (val) => val.CREATED_BY_NAME,
      sortable: true,
      width: "150px",
    },
    {
      name: "Created Date",
      selector: (val) => val.CREATED_DATE,
      sortable: true,
      width: "150px",
      cell: (val) => {
        return (
          <span>
            {formatDateToDDMMYYYY(val.CREATED_DATE)}{" "}
            {new Date(val.CREATED_DATE).toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}{" "}
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    if (props.auth_id) {
      setSelectedContacts({
        ids: [],
      });
      getContactData();
    }
  }, [dashboardQuery, props.auth_id]);

  const [transactionFlags, setTransactionFlags] = useState({
    TBODY_EMPTY: false,
  });
  const getContactData = () => {
    fetch(AXIOS.defaultPort + AXIOS.contactDataGet, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        START_DATE: dashboardQuery.START_DATE,
        END_DATE: dashboardQuery.END_DATE,
        AUTH_ID: props.auth_id,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        if (data?.value) {
          if (data?.value.length <= 0) {
            setTransactionFlags({
              TBODY_EMPTY: true,
            });
          }

          setTbody(data.value);
          setTbodyLoading(false);
        }
      })
      .catch((err) => {
        setTbodyLoading(false);
      });
  };

  return (
    <MainScreen>
      <Box
        className="dashboard-main-container contact-main"
        sx={
          {
            // minHeight:"90vh"
          }
        }
      >
        <Toaster />
        <Box
          sx={{
            pt: 3,
          }}
        >
          <span
            style={{
              fontSize: 18,
              marginLeft: 15,
              fontWeight: "bold",
            }}
          >
            Welcome {authName} !
          </span>
          {/* <img
          src={welcomeGreetings}
          style={{
            width: 200,
            height: 150,
          }}
        /> */}
        </Box>
        {empExist == false && <Page404 />}
        {empExist == "ECONNREF" && (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h3
              style={{
                color: "#205aa3",
              }}
            >
              Oops! Server Error, Please try again after some time
            </h3>
          </Box>
        )}
        {/* <Box
        style={{
          marginTop: 50,
        }}
      /> */}
        {empExist == "NODATA" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
              height: "100vh",
            }}
          >
            <Lottie
              // className="loading-lottie"
              style={{
                width: 250,
                height: 250,
              }}
              play
              //   speed={"1"}
              animationData={loadingAnime}
            />
          </Box>
        )}

        {props.components_list.includes("crm-dashboard") && (
          <Card
            sx={{
              pl: 1,
              ml: 1,
              pr: 1,
              mr: 1,
            }}
          >
            {/* <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                width: 200,
                margin: 2,
              }}
            >
              <Typography
                style={{
                  fontSize: 18,
                  color: "#000",
                  textAlign: "start",
                }}
              >
                Date Range:
              </Typography>

              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "300px",
                  width: "100px",
                  height: "35px",
                  cursor: "pointer",
                  backgroundColor: "#219bcc",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  zIndex: 0,
                  fontSize: 13,
                }}
                // onClick={handleExport}
                onClick={() => {}}
              >
                <DateRangePicker
                  defaultStartDate={dashboardQuery.START_DATE}
                  defaultEndDate={dashboardQuery.END_DATE}
                  onSelectDateRange={(startDate, endDate) => {
                    setDashboardQuery((prev) => ({
                      ...prev,
                      START_DATE: startDate,
                      END_DATE: endDate,
                    }));
                  }}
                />
              </div>
            </Box>
          </Box> */}

            <Box
              className="as-inner-container"
              sx={{
                ml: 1,
                mb: 1,
              }}
            >
              <div className="fields-width">
                <Typography
                  style={{
                    fontSize: 15,
                    color: COLORS.gray50,
                    marginTop: 10,
                  }}
                >
                  Date Range
                </Typography>
                <DateRangePicker
                  defaultStartDate={dashboardQuery.START_DATE}
                  defaultEndDate={dashboardQuery.END_DATE}
                  onSelectDateRange={(startDate, endDate) => {
                    setDashboardQuery((prev) => ({
                      ...prev,
                      START_DATE: startDate,
                      END_DATE: endDate,
                    }));
                  }}
                />
              </div>
              {selectedContacts.ids.length > 0 && (
                <>
                  {props.components_list.includes("bulk-cr-edit") && (
                    <>
                      <div className="fields-width">
                        <Typography
                          style={{
                            fontSize: 15,
                            color: COLORS.gray50,
                            marginTop: 10,
                          }}
                        >
                          CR Manager
                        </Typography>
                        <CustomDropdown
                          menuPortalTarget={true}
                          Label={"CR Manager"}
                          Options={props.employee_data}
                          Value={CRManagerQuery?.CR_MANAGER}
                          placeholder={"Select CR Manager"}
                          error={CRManagerQuery.ERROR_FLAG}
                          OnChange={(e) => {
                            // Handle dropdown change
                            setCRManagerQuery((prev) => ({
                              ...prev,
                              CR_MANAGER: e,
                              ERROR_FLAG: false,
                            }));
                          }}
                        />
                        {CRManagerQuery.ERROR_FLAG == true && (
                          <>
                            <Typography
                              style={{
                                fontSize: 15,
                                color: COLORS.red1,
                              }}
                            >
                              Please enter CR Manager
                            </Typography>
                          </>
                        )}
                      </div>
                      <div className="fields-width">
                        <button
                          className="signup-button"
                          style={{
                            marginRight: 10,
                            minWidth: 150,
                            minHeight: 34,
                            maxHeight: 30,
                            marginTop: 30,
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#23a466",
                          }}
                          onClick={() => {
                            updateBulkCRManagers();
                          }}
                        >
                          Update
                        </button>
                      </div>
                    </>
                  )}

                  {props.components_list.includes("bulk-contact-delete") && (
                    <div className="fields-width">
                      <button
                        className="signup-button"
                        style={{
                          marginRight: 10,
                          minWidth: 120,
                          minHeight: 34,
                          maxHeight: 30,
                          marginTop: 30,
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "#e63946",
                        }}
                        onClick={() => {
                          setDeleteConfirmContactModal(true);
                        }}
                      >
                        Delete Contacts
                      </button>
                    </div>
                  )}
                </>
              )}
            </Box>
            {transactionFlags?.TBODY_EMPTY == true && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Lottie
                  play
                  //   speed={"1"}
                  animationData={NoDataFoundAnime}
                  style={{
                    width: 300,
                    height: 300,
                  }}
                />
              </Box>
            )}

            <div className="dashboard-data-table-margin">
              <ReactDataTable
                data={Tbody}
                loading={TbodyLoading}
                columns={ContactColumns}
                pageCount={15}
              />
            </div>
          </Card>
        )}

        <Modal
          open={DeleteConfirmContactModal}
          onClose={() => {
            setDeleteConfirmContactModal(false);
          }}
          disableAutoFocus
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          // style={{ overflowY: "scroll", top: "5%", paddingTop: 15 }}
        >
          <Box className="delete-modal-container">
            <Box
              className="delete-modal-box"
              sx={{
                cursor: "auto",
                mt: 0,
              }}
            >
              <Typography
                sx={{
                  fontSize: 18,
                  color: COLORS.gray80,
                  fontWeight: "bold",
                }}
              >
                Confirmation
              </Typography>
              <div
                style={{
                  cursor: "pointer",
                }}
              >
                <IconContext.Provider value={{ size: 15, marginTop: 5 }}>
                  <RxCross1
                    onClick={() => {
                      setDeleteConfirmContactModal(false);
                    }}
                  />
                </IconContext.Provider>
              </div>
            </Box>

            <Divider />
            <Typography
              sx={{
                fontSize: 16,
                color: COLORS.red,
                mt: 1,
              }}
            >
              Total Contacts to be deleted: {selectedContacts.ids.length}
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                color: COLORS.gray60,
                mt: 1,
              }}
            >
              Note: This action will also remove all associated meetings for the
              contact.
            </Typography>

            <Typography
              sx={{
                fontSize: 15,
                color: COLORS.gray60,
                mt: 1,
              }}
            >
              Attention: Ensure you have saved any necessary data before
              proceeding.
            </Typography>

            <Box
              sx={{
                mb: 1,
                mt: 2,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: 15,
                  color: COLORS.gray70,
                  marginRight: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setDeleteConfirmContactModal(false);
                }}
              >
                Cancel
              </Typography>
              <CustomButton
                Label="Delete"
                buttonColor="#e63946"
                style={{
                  width: 100,
                  height: 30,
                  borderRadius: 5,
                  marginRight: "0.5%",
                }}
                labelStyle={{
                  color: "#fff",
                  fontSize: 15,
                }}
                onClick={() => {
                  deleteBulkContacts();
                }}
              />
            </Box>
          </Box>
        </Modal>
      </Box>
    </MainScreen>
  );
}

const mapStateToProps = (state) => ({
  employee_data: state.contactData.employeeList,
  auth_email_id: state.contactData.auth_email_id,
  auth_name: state.contactData.auth_name,
  auth_id: state.contactData.auth_id,
  role: state.contactData.role,
  contact_data: state.contactData.contactDataList,
  components_list: state.contactData.components_list,
});

export default connect(mapStateToProps, { EmployeeDetailsGetAction })(
  Dashboard
);
